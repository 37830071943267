/**
 * @generated SignedSource<<43ebda007896e6ea0d7cba5b8d101aa3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type MovieInput = {
  actors: ReadonlyArray<string>;
  aliases: ReadonlyArray<string>;
  countries: ReadonlyArray<string>;
  description?: string | null;
  director: ReadonlyArray<string>;
  douban_rating?: number | null;
  genres: ReadonlyArray<string>;
  id: string;
  languages: ReadonlyArray<string>;
  name?: string | null;
  original_name?: string | null;
  release_date?: string | null;
};
export type MovieEditPageUpdateMovieMutation$variables = {
  input: MovieInput;
};
export type MovieEditPageUpdateMovieMutation$data = {
  readonly update_entity: {
    readonly message?: string;
    readonly movie?: {
      readonly actors: ReadonlyArray<string>;
      readonly aliases: ReadonlyArray<string>;
      readonly countries: ReadonlyArray<string>;
      readonly description: string;
      readonly director: ReadonlyArray<string>;
      readonly genres: ReadonlyArray<string>;
      readonly id: string;
      readonly languages: ReadonlyArray<string>;
      readonly name: string;
      readonly original_name: string | null;
      readonly release_date: string;
    } | null;
  };
};
export type MovieEditPageUpdateMovieMutation = {
  response: MovieEditPageUpdateMovieMutation$data;
  variables: MovieEditPageUpdateMovieMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "movie",
        "variableName": "input"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v2 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Movie",
      "kind": "LinkedField",
      "name": "movie",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "original_name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "director",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "actors",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "genres",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "countries",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "languages",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "aliases",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "release_date",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UpdateEntityPayload",
  "abstractKey": null
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "type": "Error",
  "abstractKey": "__isError"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MovieEditPageUpdateMovieMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "update_entity",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MovieEditPageUpdateMovieMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "update_entity",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2b5124336790b33a5f5ae930c153f271",
    "id": null,
    "metadata": {},
    "name": "MovieEditPageUpdateMovieMutation",
    "operationKind": "mutation",
    "text": "mutation MovieEditPageUpdateMovieMutation(\n  $input: MovieInput!\n) {\n  update_entity(input: {movie: $input}) {\n    __typename\n    ... on UpdateEntityPayload {\n      movie {\n        id\n        name\n        original_name\n        director\n        actors\n        genres\n        countries\n        languages\n        aliases\n        release_date\n        description\n      }\n    }\n    ... on Error {\n      __isError: __typename\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "68d83b2a25845875d9bb561cebcde9ad";

export default node;

/**
 * @generated SignedSource<<6e9901f96d9e7a5f27c5db8fb2803a38>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MovieLandingPageQuery$variables = {};
export type MovieLandingPageQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"MovieLandingPageLatestMovieQuery" | "MovieLandingPageRecentActivitiesQuery">;
};
export type MovieLandingPageQuery = {
  response: MovieLandingPageQuery$data;
  variables: MovieLandingPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "kind",
    "value": "movie"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "original_name",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "cover_url",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "MovieLandingPageQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "MovieLandingPageLatestMovieQuery"
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "MovieLandingPageRecentActivitiesQuery"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "MovieLandingPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "MovieConnection",
        "kind": "LinkedField",
        "name": "latest_entities",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MovieEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Movie",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": (v2/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "latest_entities(kind:\"movie\")"
      },
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "ActivityConnection",
        "kind": "LinkedField",
        "name": "recent_activities",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ActivityEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Activity",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "verb",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "comment",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Movie",
                    "kind": "LinkedField",
                    "name": "entity",
                    "plural": false,
                    "selections": (v2/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "display_name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "username",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "avatar",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "recent_activities(kind:\"movie\")"
      }
    ]
  },
  "params": {
    "cacheID": "df646c98fcb066b37d4e7dbe82e66a1e",
    "id": null,
    "metadata": {},
    "name": "MovieLandingPageQuery",
    "operationKind": "query",
    "text": "query MovieLandingPageQuery {\n  ...MovieLandingPageLatestMovieQuery\n  ...MovieLandingPageRecentActivitiesQuery\n}\n\nfragment MovieLandingPageLatestMovieQuery on Query {\n  latest_entities(kind: movie) {\n    edges {\n      node {\n        id\n        name\n        original_name\n        cover_url\n      }\n    }\n  }\n}\n\nfragment MovieLandingPageRecentActivitiesQuery on Query {\n  recent_activities(kind: movie) {\n    edges {\n      node {\n        __typename\n        id\n        verb\n        comment\n        entity {\n          id\n          name\n          original_name\n          cover_url\n        }\n        user {\n          display_name\n          username\n          avatar\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0e3ed1c2a3aea5b13d926d569d3f5b31";

export default node;

import { faRotateRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export default function FormTextarea({
  id,
  label,
  value,
  onChange,
  twoline = false,
  width = "w-96",
  reset = false,
  onReset = null,
}: {
  id: string;
  label: string;
  value: string;
  onChange: (v: string) => void;
  twoline?: boolean;
  width?: string;
  reset?: boolean;
  onReset?: (() => void) | null;
}): JSX.Element {
  return (
    <div className={`flex items-start ${twoline ? "flex-col" : ""}`}>
      <label
        htmlFor={id}
        className={`text-sm font-medium text-gray-700 w-32 inline-block mr-4 flex-0 leading-8 ${
          twoline ? "text-left" : "text-right"
        }`}
      >
        {label}
        {onReset !== null && reset && (
          <FontAwesomeIcon
            icon={faRotateRight}
            className="ml-2 text-orange-400 cursor-pointer"
            onClickCapture={() => {
              onReset();
            }}
          />
        )}
      </label>
      <textarea
        className={`border border-gray-200 px-3 py-2 rounded flex-0 min-h-[240px] ${width}`}
        id={id}
        value={value}
        onChange={(e) => {
          onChange(e.target.value);
        }}
      />
    </div>
  );
}

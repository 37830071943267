import React, {
  type Dispatch,
  type SetStateAction,
  useEffect,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { useMastodonApi } from "../utilities/Mastodon";
import Status from "../components/Status";
import { type MastodonStatus } from "../__types__/MastodonStatus";

interface Props {
  status: MastodonStatus;
  setStatus: Dispatch<SetStateAction<MastodonStatus | null>>;
}

interface MastodonContext {
  ancestors: MastodonStatus[];
  descendants: MastodonStatus[];
}

export default function ThreadList({ status, setStatus }: Props): JSX.Element {
  const { statusId } = useParams();
  const [ancestors, setAncestors] = useState<MastodonStatus[]>([]);
  const [descendants, setDescendants] = useState<MastodonStatus[]>([]);
  const mastodonApi = useMastodonApi();

  if (statusId == null) {
    return <div>Not Found</div>;
  }

  useEffect(() => {
    mastodonApi
      .getStatusContext(statusId)
      .then(async (data) => {
        const context = (await data.json()) as MastodonContext;
        setAncestors(context.ancestors);
        setDescendants(context.descendants);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [statusId]);

  return (
    <div>
      {ancestors.map((st) => (
        <Status
          key={st.id}
          status={st}
          secondary
          updateStatus={(id: string, fields: Partial<MastodonStatus>) => {
            setAncestors((prev) =>
              prev.map((s) => {
                if (s.id === id) {
                  return {
                    ...s,
                    ...fields,
                  };
                }
                return s;
              })
            );
          }}
          className="no-rounded first:rounded-t-md last:rounded-b-md border-b border-gray-100 bg-[#FDFDFD] dark:border-gray-900 dark:bg-[#2a3342]"
        />
      ))}
      <Status
        status={status}
        className="no-rounded first:rounded-t-md first:rounded-b-none last:rounded-b-md last:rounded-t-none primary border-b border-gray-100 dark:border-gray-900"
        updateStatus={(id: string, fields: Partial<MastodonStatus>) => {
          setStatus((prev) => {
            if (prev?.id !== id) {
              return status;
            }
            return {
              ...status,
              ...fields,
            };
          });
        }}
      />
      {descendants.map((st) => (
        <Status
          key={st.id}
          status={st}
          secondary
          className="no-rounded first:rounded-t-md last:rounded-b-md border-b border-gray-100 bg-[#FDFDFD] dark:border-gray-900 dark:bg-[#2a3342]"
          updateStatus={(id: string, fields: Partial<MastodonStatus>) => {
            setAncestors((prev) =>
              prev.map((s) => {
                if (s.id === id) {
                  return {
                    ...s,
                    ...fields,
                  };
                }
                return s;
              })
            );
          }}
        />
      ))}
    </div>
  );
}

import React, { useEffect } from "react";
import { useFragment, useLazyLoadQuery } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import filterNonNulls from "@utilities/filterNonNulls";
import { Link } from "react-router-dom";
import humanizeVerb from "@utilities/humanizeVerb";
import { Main, TwoColumn } from "@components/Layout";
import { type MovieLandingPageLatestMovieQuery$key } from "./__generated__/MovieLandingPageLatestMovieQuery.graphql";
import { type MovieLandingPageRecentActivitiesQuery$key } from "./__generated__/MovieLandingPageRecentActivitiesQuery.graphql";
import { type MovieLandingPageQuery } from "./__generated__/MovieLandingPageQuery.graphql";

interface Props {}

function LatestMovies({
  query,
}: {
  query: MovieLandingPageLatestMovieQuery$key;
}): JSX.Element {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { latest_entities } = useFragment(
    graphql`
      fragment MovieLandingPageLatestMovieQuery on Query {
        latest_entities(kind: movie) {
          edges {
            node {
              id
              name
              original_name
              cover_url
            }
          }
        }
      }
    `,
    query
  );
  const movies = filterNonNulls(
    (latest_entities.edges ?? []).map((edge) => edge?.node)
  );
  return (
    <div className="card pb-2">
      <h2 className="card-title">最新电影条目</h2>
      <div className="flex flex-col mx-4 py-2 scroll-pane">
        <ul className="flex items-stretch space-x-4">
          {movies.map((movie) => (
            <li key={movie.id} className="w-24 shrink-0">
              <Link
                to={`/movie/${movie.id}`}
                className="flex items-center h-full"
              >
                <img
                  src={movie.cover_url}
                  alt={movie.name}
                  className="select-none"
                />
              </Link>
            </li>
          ))}
        </ul>
        <ul className="flex items-start space-x-4">
          {movies.map((movie) => (
            <li key={movie.id} className="w-24 shrink-0">
              <Link to={`/movie/${movie.id}`}>
                <h3 className="pt-2 text-xs">
                  {movie.name ?? movie.original_name}
                </h3>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

function RecentActivities({
  query,
}: {
  query: MovieLandingPageRecentActivitiesQuery$key;
}): JSX.Element {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { recent_activities } = useFragment(
    graphql`
      fragment MovieLandingPageRecentActivitiesQuery on Query {
        recent_activities(kind: movie) {
          edges {
            node {
              __typename
              id
              verb
              comment

              entity {
                id
                name
                original_name
                cover_url
              }

              user {
                display_name
                username
                avatar
              }
            }
          }
        }
      }
    `,
    query
  );
  const activities = filterNonNulls(
    (recent_activities.edges ?? []).map((edge) => edge?.node)
  );
  return (
    <div className="card mt-4 pb-2">
      <h2 className="card-title">最近电影动态</h2>
      <div className="flex flex-col mx-4 py-2 my-2 scroll-pane">
        <ul className="flex items-stretch space-x-4">
          {activities.map(({ entity }) => (
            <li key={entity.id} className="w-24 shrink-0">
              <Link
                to={`/movie/${entity.id}`}
                className="flex items-center h-full"
              >
                <img
                  src={entity.cover_url}
                  alt={entity.name}
                  className="select-none"
                />
              </Link>
            </li>
          ))}
        </ul>
        <ul className="flex items-start space-x-4">
          {activities.map(({ id, verb, user, entity }) => (
            <li key={id} className="w-24 shrink-0 text-xs pt-2">
              <Link
                to={`/@${user.username}`}
                className="text-orange-600 hover:underline"
              >
                {user.display_name}
              </Link>
              <span className="text-gray-500 dark:text-gray-400 ml-1 mr-1 select-none">
                {humanizeVerb(verb, "看")}
              </span>
              <Link to={`/movie/${entity.id}`} className="hover:underline">
                {entity.name ?? entity.original_name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default function MovieLandingPage(_: Props): JSX.Element {
  const query = useLazyLoadQuery<MovieLandingPageQuery>(
    graphql`
      query MovieLandingPageQuery {
        ...MovieLandingPageLatestMovieQuery
        ...MovieLandingPageRecentActivitiesQuery
      }
    `,
    {}
  );
  useEffect(() => {
    document.title = "电影 | 贝塔镇";
  }, []);

  return (
    <TwoColumn>
      <Main>
        <LatestMovies query={query} />
        <RecentActivities query={query} />
      </Main>
    </TwoColumn>
  );
}

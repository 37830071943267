import { useState } from "react";
import { type MastodonStatus } from "../__types__/MastodonStatus";

export interface UseTimeline {
  setInitialTimeline: (timeline: MastodonStatus[]) => void;
  prependStatus: (status: MastodonStatus) => void;
  appendStatuses: (statuses: MastodonStatus[]) => void;
  updateStatus: (id: string, status: Partial<MastodonStatus>) => void;
  deleteStatus: (id: string) => void;
  lastStatus: () => MastodonStatus | undefined;
}

export default function useTimeline(): [MastodonStatus[], UseTimeline] {
  const [timeline, setTimeline] = useState<MastodonStatus[]>([]);
  return [
    timeline,
    {
      setInitialTimeline: (statuses: MastodonStatus[]) => {
        setTimeline(statuses);
      },
      prependStatus: (status: MastodonStatus) => {
        setTimeline([status, ...timeline]);
      },
      appendStatuses: (statuses: MastodonStatus[]) => {
        setTimeline((prev) => [...prev, ...statuses]);
      },
      updateStatus: (id: string, field: Partial<MastodonStatus>) => {
        setTimeline((prev) => {
          let updated = false;
          return [...prev].map((status) => {
            if (updated) return status;
            if (status.id === id) {
              updated = true;
              return { ...status, ...field };
            }
            if (status.reblog?.id === id) {
              return {
                ...status,
                reblog: { ...status.reblog, ...field },
              };
            }
            return status;
          });
        });
      },
      deleteStatus: (id: string) => {
        setTimeline((prev) => prev.filter((status) => status.id !== id));
      },
      lastStatus: () => timeline[timeline.length - 1],
    },
  ];
}

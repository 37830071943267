import { type IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faMask,
  faEarth,
  faEnvelope,
  faLock,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, Menu, MenuItem } from "@mui/material";
import React, { useCallback, useState } from "react";
import { type Visibility } from "../utilities/Mastodon";

function visibilityToIcon(visibility: Visibility): IconProp {
  switch (visibility) {
    case "public":
      return faEarth;
    case "unlisted":
      return faMask;
    case "private":
      return faLock;
    case "direct":
      return faEnvelope;
    default:
      return faEarth;
  }
}

function VisibilitySelectItem({
  visibility,
  name,
  description = " ",
  selected,
  onSelect,
}: {
  visibility: Visibility;
  name: string;
  description?: string;
  selected: Visibility;
  onSelect: (vis: Visibility) => void;
}): JSX.Element {
  return (
    <MenuItem
      className="flex items-center"
      selected={selected === visibility}
      onClick={() => {
        onSelect(visibility);
      }}
    >
      <div className="w-6 flex justify-center">
        <FontAwesomeIcon
          icon={visibilityToIcon(visibility)}
          className="shrink-0"
        />
      </div>
      <div className="flex flex-col ml-2 h-10 justify-center">
        <span className="text-sm font-medium">{name}</span>
        <small className="text-xs text-gray-700">{description}</small>
      </div>
    </MenuItem>
  );
}

export default function VisibilitySelect({
  className = "",
  value,
  onChange,
}: {
  className?: React.HTMLAttributes<HTMLDivElement>["className"];
  value: Visibility;
  onChange: (vis: Visibility) => void;
}): JSX.Element {
  const [open, setOpen] = useState(false);
  const ref = React.useRef<HTMLButtonElement>(null);
  const onSelected = useCallback((vis: Visibility) => {
    onChange(vis);
    setOpen(false);
  }, []);
  return (
    <div
      className={`mr-2 w-8 h-8 flex items-center justify-center ${
        className ?? ""
      }`}
    >
      <IconButton
        size="small"
        ref={ref}
        onClick={(e) => {
          setOpen(true);
        }}
      >
        <FontAwesomeIcon
          icon={visibilityToIcon(value)}
          className="text-gray-700 dark:text-gray-200"
        />
      </IconButton>
      <Menu
        open={open}
        anchorEl={ref.current}
        onClose={() => {
          setOpen(false);
        }}
      >
        <VisibilitySelectItem
          selected={value}
          onSelect={onSelected}
          visibility="public"
          name="公开"
          description="所有人可见"
        />
        <VisibilitySelectItem
          selected={value}
          onSelect={onSelected}
          visibility="unlisted"
          name="非公开"
          description="所有人可见，但不加入公开时间轴"
        />
        <VisibilitySelectItem
          selected={value}
          onSelect={onSelected}
          visibility="private"
          name="仅关注者可见"
        />
        <VisibilitySelectItem
          selected={value}
          onSelect={onSelected}
          visibility="direct"
          name="私信"
          description="只有被提及的用户才能看到"
        />
      </Menu>
    </div>
  );
}

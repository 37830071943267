import { type ActivityVerb } from "../entity/MoviePage/__generated__/MoviePageMarkHistory.graphql";

export default function humanizeVerb(verb: ActivityVerb, act: string): string {
  if (verb === "WISHLIST") {
    return `想${act}`;
  }
  if (verb === "IN_PROGRESS") {
    return `在${act}`;
  }
  if (verb === "FINISHED") {
    return `${act}过`;
  }
  return verb;
}

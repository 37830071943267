import React, { useCallback, useEffect, useMemo } from "react";
import {
  type LoadMoreFn,
  useLazyLoadQuery,
  usePaginationFragment,
} from "react-relay";
import graphql from "babel-plugin-relay/macro";
import { Link, useParams } from "react-router-dom";
import { Aside, Main, TwoColumn } from "@components/Layout";
import { type SearchPageQuery } from "./__generated__/SearchPageQuery.graphql";
import {
  type SearchPage_EntryResult_movie$data,
  type SearchPage_EntryResult_movie$key,
} from "./__generated__/SearchPage_EntryResult_movie.graphql";
import filterNonNulls from "../utilities/filterNonNulls";
import RatingSelect from "../components/RatingSelect";
import NotFoundCat from "./not-found-cat.png";
import NotFoundDog from "./not-found-dog.png";

interface Props {}

function SearchResult({
  keyword,
  query,
}: {
  keyword: string;
  query: SearchPage_EntryResult_movie$key;
}): JSX.Element {
  const { data, loadNext } = usePaginationFragment<
    SearchPageQuery,
    SearchPage_EntryResult_movie$key
  >(
    graphql`
      fragment SearchPage_EntryResult_movie on Query
      @argumentDefinitions(
        query: { type: "String!" }
        first: { type: "Int", defaultValue: 20 }
        cursor: { type: "String", defaultValue: "" }
      )
      @refetchable(queryName: "SearchPage_EntryResult_movie_Query") {
        search(keyword: $query) {
          entries(first: $first, after: $cursor)
            @connection(key: "SearchPage_EntryResult_movie_entries") {
            edges {
              cursor
              node {
                id
                name
                original_name
                cover_url
                douban_rating

                countries
                release_date
                director
                actors
              }
            }
          }
        }
      }
    `,
    query
  );

  const result = filterNonNulls(data?.search?.entries?.edges ?? []);

  if (result.length === 0) {
    return <NoSearchResult query={keyword} />;
  }

  return (
    <TwoColumn className="mb-24">
      <Main className="flex flex-col">
        <SearchResultList
          keyword={keyword}
          result={result}
          loadNext={loadNext}
        />
      </Main>
      <Aside>
        {result.length !== null && (
          <>
            <h2 className="font-bold">没有找到想要的条目？</h2>
            <AddEntityLinks query={keyword} />
          </>
        )}
      </Aside>
    </TwoColumn>
  );
}

type Unpacked<T> = T extends ReadonlyArray<infer U> ? U : T;
type SearchResultType = Exclude<
  Unpacked<
    Exclude<
      Exclude<
        Exclude<SearchPage_EntryResult_movie$data["search"], null>["entries"],
        null
      >["edges"],
      null
    >
  >,
  null
>;

function SearchResultList({
  keyword,
  result,
  loadNext,
}: {
  keyword: string;
  result: SearchResultType[];
  loadNext: LoadMoreFn<SearchPageQuery>;
}): JSX.Element {
  return (
    <div>
      <h2 className="text-xl font-bold">搜索「{keyword}」</h2>
      <ul className="space-y-2 mt-4">
        {result.map(({ node }) => {
          const metadata: string[] = [];
          metadata.push(...node.countries.slice(0, 2));
          if (node.release_date !== "") {
            metadata.push(node.release_date.split("-")[0]);
          }
          metadata.push(...node.director.slice(0, 2));
          metadata.push(...node.actors.slice(0, 2));
          return (
            <li key={node.id} className="card">
              <Link to={`/movie/${node.id}`} className="flex p-4">
                <div className="w-24 rounded overflow-hidden shrink-0">
                  <img
                    src={node.cover_url}
                    alt={node.name}
                    className="w-full"
                  />
                </div>
                <div className="ml-4">
                  <h2 className="font-bold text-lg">{node.name}</h2>
                  <h3 className="text-gray-500">{node.original_name}</h3>
                  {node?.douban_rating != null && (
                    <div className="flex">
                      <RatingSelect
                        value={node.douban_rating / 20}
                        readonly
                        className="text-sm -space-x-0"
                      />
                      <span className="ml-2 font-medium text-orange-600">
                        {node.douban_rating / 10}
                      </span>
                    </div>
                  )}
                  <div className="text-gray-500 text-sm mt-2">
                    {metadata.join(" / ")}
                  </div>
                </div>
              </Link>
            </li>
          );
        })}
        <li className="flex">
          <button
            type="button"
            className="text-center w-full py-2 bg-orange-100 text-orange-900 rounded mt-2 hover:bg-orange-200 transition-colors"
            onClickCapture={(e) => {
              e.preventDefault();
              loadNext(20);
            }}
          >
            加载更多
          </button>
        </li>
      </ul>
    </div>
  );
}

function AddEntityLinks({
  query,
  className = "",
}: {
  className?: Element["className"];
  query: string;
}): JSX.Element {
  const withQuery = useCallback(
    (url: string) => `${url}?name=${query}`,
    [query]
  );
  return (
    <ul
      className={`list-inside list-square mt-2 text-orange-200 space-y-1 ${className}`}
    >
      <li>
        <Link
          to={withQuery("/movie/create")}
          className="hover:underline text-orange-700"
        >
          添加电影
        </Link>
      </li>
      <li>
        <Link
          to={withQuery("/book/create")}
          className="hover:underline text-orange-700"
        >
          添加书籍
        </Link>
      </li>
      <li>
        <Link
          to={withQuery("/music/create")}
          className="hover:underline text-orange-700"
        >
          添加音乐
        </Link>
      </li>
      <li>
        <Link
          to={withQuery("/game/create")}
          className="hover:underline text-orange-700"
        >
          添加游戏
        </Link>
      </li>
    </ul>
  );
}

function NoSearchResult({ query }: { query: string }): JSX.Element {
  const selection = useMemo(() => {
    const num = Math.floor(Math.random() * 2);
    if (num === 0) {
      return NotFoundCat;
    }
    return NotFoundDog;
  }, []);
  return (
    <div className="flex items-center justify-center w-2/3">
      <img src={selection} alt="not found" className="w-56" />
      <div className="ml-4">
        <h2 className="text-lg text-stone-700 font-bold">
          抱歉，没有找到相关条目…
        </h2>
        <AddEntityLinks className="mt-4" query={query} />
      </div>
    </div>
  );
}

export default function SearchPage(_: Props): JSX.Element {
  const { query } = useParams();

  if (query == null) {
    return <div className="flex mb-24">搜索</div>;
  }

  const result = useLazyLoadQuery<SearchPageQuery>(
    graphql`
      query SearchPageQuery($query: String!) {
        ...SearchPage_EntryResult_movie @arguments(query: $query)
      }
    `,
    { query }
  );

  useEffect(() => {
    document.title = `搜索「${query}」 | 贝塔镇`;
  });

  return <SearchResult query={result} keyword={query} />;
}

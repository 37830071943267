import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";

export function Main({
  children,
  className = "",
}: {
  children: React.ReactNode;
  className?: string;
}): JSX.Element {
  return (
    <div className={`w-full md:w-2/3 px-2 md:pl-0 ${className}`}>
      {children}
    </div>
  );
}

export function Aside({
  children,
  className = "",
}: {
  children: React.ReactNode;
  className?: string;
}): JSX.Element {
  return (
    <div className={`w-full md:w-1/3 px-2 md:pr-0 my-2 md:my-0 ${className}`}>
      {children}
    </div>
  );
}

export function TwoColumn({
  children,
  className = "",
  asideFirst = false,
}: {
  children: React.ReactNode;
  className?: string;
  asideFirst?: boolean;
}): JSX.Element {
  let left = null;
  let right = null;
  const content: React.ReactNode[] = [];

  React.Children.forEach(children, (child) => {
    if (!React.isValidElement(child)) return;
    if (child.type === Main) {
      left = child;
    } else if (child.type === Aside) {
      right = child;
    } else {
      content.push(child);
    }
  });

  const md = useMediaQuery("(min-width:768px)");

  return (
    <div className={`flex flex-col md:flex-row mb-24 ${className}`}>
      {(!asideFirst ? false : !md) && right !== null && right}
      {left !== null && left}
      {(!asideFirst ? true : md) && right !== null && right}
      {content}
    </div>
  );
}

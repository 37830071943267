import React, { Suspense, useEffect, useState } from "react";
import { Skeleton } from "@mui/material";
import { suspend } from "suspend-react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { Aside, Main, TwoColumn } from "@components/Layout";
import ThreadList from "./ThreadList";
import AccountCard from "../components/AccountCard";
import { useMastodonApi } from "../utilities/Mastodon";
import { type MastodonStatus } from "../__types__/MastodonStatus";
import { CurrentDomain } from "../State";

interface Props {}

function StatusPageSkeleton(): JSX.Element {
  return (
    <TwoColumn>
      <Main>
        <div className="card flex overflow-hidden p-5">
          <div className="mr-3">
            <Skeleton variant="rounded" width={48} height={48} />
          </div>
          <div className="flex flex-col flex-1">
            <div className="flex justify-between mb-1">
              <Skeleton
                className="w-24"
                variant="text"
                sx={{ fontSize: "1rem" }}
              />
              <Skeleton
                className="w-14"
                variant="text"
                sx={{ fontSize: "1rem" }}
              />
            </div>
            <Skeleton
              className="w-96"
              variant="text"
              sx={{ fontSize: "1rem" }}
            />
            <Skeleton
              className="w-64"
              variant="text"
              sx={{ fontSize: "1rem" }}
            />
            <Skeleton
              className="w-48"
              variant="text"
              sx={{ fontSize: "1rem" }}
            />
          </div>
        </div>
      </Main>
    </TwoColumn>
  );
}

function StatusPageInner({
  statusId,
  acct,
}: {
  statusId: string;
  acct: string;
}): JSX.Element {
  const mastodonApi = useMastodonApi();
  const currentDomain = useRecoilValue(CurrentDomain);

  const [status, setStatus] = useState<MastodonStatus | null>(null);
  const initStatus = suspend(async () => {
    const response = await mastodonApi.getStatus(statusId);
    const resp = (await response.json()) as MastodonStatus;
    return resp;
  }, [statusId]);

  useEffect(() => {
    setStatus(initStatus);
  }, [initStatus]);

  if (status === null) {
    return <StatusPageSkeleton />;
  }

  let statusAccount = status?.account.acct;
  if (!statusAccount.includes("@")) {
    statusAccount += `@${currentDomain}`;
  }

  if (initStatus == null || statusAccount !== acct?.slice(1)) {
    return <div>Not Found</div>;
  }

  return (
    <TwoColumn className="mb-24">
      <Main>
        <ThreadList status={status} setStatus={setStatus} />
      </Main>
      <Aside>
        <AccountCard
          coverImage={status.account.header ?? null}
          avatar={status.account.avatar ?? null}
          displayName={status.account.display_name ?? ""}
          username={statusAccount}
        />
      </Aside>
    </TwoColumn>
  );
}

export default function StatusPage(_: Props): JSX.Element {
  const { statusId, acct } = useParams();
  const domain = acct?.slice(1).split("@")[1];
  const navigate = useNavigate();
  const currentDomain = useRecoilValue(CurrentDomain);

  useEffect(() => {
    if (
      acct != null &&
      statusId != null &&
      domain === undefined &&
      currentDomain !== ""
    ) {
      navigate(`/${acct}@${currentDomain}/${statusId}`, { replace: true });
    }
  }, [acct, domain]);

  if (domain === undefined && currentDomain === "") {
    return <div>Not Found</div>;
  }

  if (statusId == null || acct == null || !acct?.startsWith("@")) {
    return <div>Not Found</div>;
  }

  return (
    <Suspense fallback={<StatusPageSkeleton />}>
      <StatusPageInner statusId={statusId} acct={acct} />
    </Suspense>
  );
}

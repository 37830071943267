import React, {
  forwardRef,
  type Ref,
  useRef,
  useState,
  type SetStateAction,
  type Dispatch,
  useEffect,
} from "react";
import { useMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBook,
  faCompactDisc,
  faFilm,
  faGamepad,
  faSpinner,
  type IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import FormControl from "@components/FormControl";
import ChipsInput from "@components/ChipsInput";
import FormTextarea from "@components/FormTextarea";
import { useNavigate } from "react-router-dom";
import { Portal } from "@mui/material";
import { type UploadableMap } from "relay-runtime";
import { useSnackbar } from "notistack";
import { type CreateEntityPageImportEntityMutation } from "./__generated__/CreateEntityPageImportEntityMutation.graphql";
import {
  type CreateEntityPageCreateEntityMutation,
  type CreateEntityPageCreateEntityMutation$variables,
  type CreateEntityMovieInput,
} from "./__generated__/CreateEntityPageCreateEntityMutation.graphql";

type EntityKind = "movie" | "game" | "book" | "music";

interface ImportedResult {
  title: string;
  image: string | null;
  description: string | null;
  release_date: string | null;
  actors: string[];
  directors: string[];
  genres: string[];
}

interface Props {
  kind: EntityKind;
}

type MutableArrayField<T> = {
  [K in keyof T]: T[K] extends ReadonlyArray<infer U> ? U[] : T[K];
};

type MutationInputType<T> = Partial<
  MutableArrayField<Exclude<T, null | undefined>>
>;

type MovieInputType = MutationInputType<CreateEntityMovieInput>;

interface EntityInputType {
  movie?: MovieInputType;
}

export default function CreateEntityPage({
  kind: initialKind,
}: Props): JSX.Element {
  const editStepRef = useRef<HTMLDivElement>(null);
  const [kind, setKind] = useState<EntityKind>(initialKind);
  const [link, setLink] = useState<string>("");
  const [imported, setImported] = useState<ImportedResult | null>(null);
  const [cover, setCover] = useState<File | null>(null);
  const [entity, setEntity] = useState<EntityInputType>({});
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  useEffect(() => {
    document.title = `创建条目 | 贝塔镇`;
  }, []);

  const [createEntity, createEntityInFlight] =
    useMutation<CreateEntityPageCreateEntityMutation>(graphql`
      mutation CreateEntityPageCreateEntityMutation(
        $input: CreateEntityInput!
      ) {
        create_entity(input: $input) {
          __typename
          ... on CreateEntityPayload {
            movie {
              id
            }
          }
          ... on AlreadyExists {
            path
          }
          ... on Error {
            message
          }
        }
      }
    `);

  const importRef = useRef<HTMLDivElement>(null);

  return (
    <div className="flex mb-32">
      <form
        className="w-2/3 mx-auto space-y-4"
        onSubmitCapture={async (e) => {
          e.preventDefault();
          if (createEntityInFlight) {
            return;
          }

          const input: CreateEntityPageCreateEntityMutation$variables["input"] =
            {
              kind,
              link,
            };

          if (kind === "movie") {
            input.movie = {
              name: entity.movie?.name ?? imported?.title ?? "",
              original_name:
                entity.movie?.original_name ?? imported?.title ?? "",
              directors: entity.movie?.directors ?? imported?.directors ?? [],
              actors: entity.movie?.actors ?? imported?.actors ?? [],
              genres: entity.movie?.genres ?? imported?.genres ?? [],
              countries: entity.movie?.countries ?? [],
              languages: entity.movie?.languages ?? [],
              aliases: entity.movie?.aliases ?? [],
              release_date:
                entity.movie?.release_date ?? imported?.release_date ?? "",
              description:
                entity.movie?.description ?? imported?.description ?? "",
            };

            if (
              input.movie.name.length === 0 &&
              input.movie.original_name.length === 0
            ) {
              enqueueSnackbar("电影名称或原名不能为空", { variant: "error" });
              return;
            }
          } else {
            enqueueSnackbar("暂不支持的条目类型", { variant: "error" });
            return;
          }

          const uploadables: UploadableMap = {};

          if (cover != null) {
            uploadables["input.cover"] = cover;
          } else if (imported?.image != null) {
            try {
              uploadables["input.cover"] = await (
                await fetch(imported?.image, {
                  referrerPolicy: "no-referrer",
                })
              ).blob();
            } catch (err) {
              if (err instanceof Error) {
                enqueueSnackbar(`封面图片下载失败：${err.message}`, {
                  variant: "error",
                });
              } else {
                // eslint-disable-next-line no-console
                console.error(err);
                enqueueSnackbar(`封面图片下载失败：未知原因`, {
                  variant: "error",
                });
              }
              return;
            }
          }

          createEntity({
            variables: {
              input,
            },
            uploadables,
            onCompleted: (data) => {
              // eslint-disable-next-line no-underscore-dangle
              const typeName = data.create_entity.__typename;
              if (typeName === "CreateEntityPayload") {
                const { movie } = data.create_entity;
                if (movie != null) {
                  navigate(`/movie/${movie.id}`);
                }
              } else if (typeName === "AlreadyExists") {
                navigate(data.create_entity.path as string);
              } else if (data.create_entity.message != null) {
                enqueueSnackbar(data.create_entity.message, {
                  variant: "error",
                });
              }
            },
            onError: (err) => {
              enqueueSnackbar(`创建条目失败：${err.message}`, {
                variant: "error",
              });
            },
          });
        }}
      >
        <div ref={importRef} />
        <EditStep
          kind={kind}
          setKind={setKind}
          entity={entity}
          setEntity={setEntity}
          ref={editStepRef}
          imported={imported}
        />
        <UploadCoverStep
          imported={imported}
          cover={cover}
          setCover={setCover}
        />
        <button
          type="submit"
          className="w-48 mx-auto py-4 rounded-lg bg-orange-600 text-center text-white font-bold select-none block"
          disabled={createEntityInFlight}
        >
          {createEntityInFlight ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : (
            "创建条目"
          )}
        </button>
      </form>
      {/* Need this arrangement due to nested form */}
      <Portal container={() => importRef.current}>
        <ImportStep
          link={link}
          setLink={setLink}
          onSkip={() => {
            editStepRef.current?.scrollIntoView({ behavior: "smooth" });
          }}
          setImported={setImported}
        />
      </Portal>
    </div>
  );
}

function UploadCoverStep({
  imported,
  cover,
  setCover,
}: {
  imported: ImportedResult | null;
  cover: File | null;
  setCover: (cover: File | null) => void;
}): JSX.Element {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const preview =
    cover !== null ? URL.createObjectURL(cover) : imported?.image ?? null;

  return (
    <div className="card flex flex-col items-center p-6">
      <StepHeader step={3} label="上传封面" />
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div
        className="rounded border-4 border-dashed min-h-[280px] min-w-[200px] max-h-[500px] max-w-[300px] bg-gray-100 flex items-center justify-center overflow-clip cursor-pointer"
        onDragOverCapture={(e) => {
          e.preventDefault();
          e.dataTransfer.dropEffect = "copy";
        }}
        onDropCapture={(e) => {
          e.preventDefault();
          const { files } = e.dataTransfer;
          if (files.length > 0) {
            const file = files[0];
            setCover(file);
          }
        }}
        onClick={() => {
          inputRef.current?.click();
        }}
      >
        <input
          type="file"
          className="hidden"
          ref={inputRef}
          accept={["image/*"].join(",")}
          onChange={(e) => {
            const { files } = e.target;
            if (files !== null && files.length > 0) {
              const file = files[0];
              setCover(file);
            }
          }}
        />
        {preview !== null ? (
          <img
            src={preview}
            alt="上传的封面图片"
            className="h-full rounded overflow-clip"
          />
        ) : (
          <span className="text-gray-400 font-bold select-none">
            拖拽至此上传
          </span>
        )}
      </div>
      {imported?.image != null && cover !== null && (
        <button
          type="button"
          className="mt-2 rounded bg-gray-600 text-white px-4 py-2 text-xs hover:bg-gray-500"
          onClick={(e) => {
            e.preventDefault();
            setCover(null);
          }}
        >
          重置
        </button>
      )}
    </div>
  );
}

function ImportStep({
  link,
  setLink,
  onSkip,
  setImported,
}: {
  link: string;
  setLink: (v: string) => void;
  onSkip: () => void;
  setImported: (v: ImportedResult) => void;
}): JSX.Element {
  const importInputId = "import_url";
  const showImportButton = link.length > 0;
  const showImportButtonStyle = showImportButton
    ? "bg-orange-600 text-white"
    : "bg-gray-300 text-gray-800";

  const { enqueueSnackbar } = useSnackbar();

  const [importEntity, importEntityInFlight] =
    useMutation<CreateEntityPageImportEntityMutation>(
      graphql`
        mutation CreateEntityPageImportEntityMutation($url: String!) {
          import_entity(input: { url: $url }) {
            __typename

            ... on ImportEntityPayload {
              result
            }
            ... on AlreadyExists {
              path
            }
            ... on Error {
              message
            }
          }
        }
      `
    );

  let importButton;
  if (importEntityInFlight) {
    importButton = <FontAwesomeIcon icon={faSpinner} spin />;
  } else if (showImportButton) {
    importButton = "导入";
  } else {
    importButton = "跳过";
  }

  const navigate = useNavigate();

  return (
    <div className="card flex flex-col items-center p-6">
      <StepHeader step={1} label="导入条目" />
      <form
        className="mt-4 flex flex-col items-center"
        onSubmitCapture={(e) => {
          e.preventDefault();
          if (importEntityInFlight) {
            return;
          }
          if (!showImportButton || link.length === 0) {
            onSkip();
            return;
          }

          try {
            // eslint-disable-next-line no-new
            new URL(link);
          } catch (err) {
            enqueueSnackbar(`链接格式错误`, { variant: "error" });
            return;
          }

          importEntity({
            variables: {
              url: link,
            },
            onCompleted: (data) => {
              // eslint-disable-next-line no-underscore-dangle
              const typeName = data.import_entity?.__typename ?? "";

              if (typeName === "ImportEntityPayload") {
                setImported(
                  JSON.parse(data.import_entity.result ?? "") as ImportedResult
                );
                onSkip();
                return;
              }
              if (typeName === "AlreadyExists") {
                navigate(data.import_entity.path ?? "");
                return;
              }
              if (data.import_entity?.message != null) {
                enqueueSnackbar(`导入失败：${data.import_entity.message}`, {
                  variant: "error",
                });
              }
            },
            onError: (error) => {
              enqueueSnackbar(`导入失败：${error.message}`, {
                variant: "error",
              });
            },
          });
        }}
      >
        <input
          type="text"
          name={importInputId}
          id={importInputId}
          className="w-128 border rounded px-3 py-2"
          placeholder="网址（例如：https://www.imdb.com/title/tt0111161/）"
          value={link}
          onChange={(e) => {
            setLink(e.target.value);
          }}
        />

        <div className="mt-4">
          <button
            type="submit"
            className={`px-4 py-1 rounded transition-colors font-medium ${showImportButtonStyle}`}
          >
            {importButton}
          </button>
        </div>
      </form>
    </div>
  );
}

function StepHeader({
  step,
  label,
}: {
  step: number;
  label: string;
}): JSX.Element {
  return (
    <div className="flex items-center pt-2 pb-4 select-none">
      <div className="rounded-full w-5 h-5 block bg-orange-600 text-white text-center leading-5 font-bold text-xs select-none">
        {step}
      </div>
      <h2 className="leading-5 ml-2 text-xl font-semibold text-orange-600">
        {label}
      </h2>
    </div>
  );
}

// eslint-disable-next-line prefer-arrow-callback
const EditStep = forwardRef(function EditStep(
  {
    kind,
    setKind,
    imported,
    entity,
    setEntity,
  }: {
    kind: EntityKind;
    setKind: (v: EntityKind) => void;
    entity: EntityInputType;
    setEntity: Dispatch<SetStateAction<EntityInputType>>;
    imported: ImportedResult | null;
  },
  ref: Ref<HTMLDivElement>
): JSX.Element {
  let form: JSX.Element = <span />;
  if (kind === "movie") {
    form = (
      <CreateMovieForm
        imported={imported}
        movie={entity.movie ?? {}}
        setMovie={(update) => {
          if (typeof update === "function") {
            setEntity((e) => ({
              ...e,
              movie: update(e.movie ?? {}),
            }));
          } else {
            setEntity((e) => ({
              ...e,
              movie: update,
            }));
          }
        }}
      />
    );
  } else {
    form = <span>暂未开放</span>;
  }

  return (
    <div className="card mt-4 flex flex-col items-center p-6" ref={ref}>
      <StepHeader step={2} label="条目信息" />
      <div className="mt-4 flex flex-col space-y-4">
        <div className="flex space-x-4 w-128">
          {[
            ["电影", "movie", faFilm],
            ["音乐", "music", faCompactDisc],
            ["书籍", "book", faBook],
            ["游戏", "game", faGamepad],
          ].map(([label, kindId, icon]) => {
            const isSelected = kindId === kind;
            const selectedStyle = isSelected
              ? "bg-orange-500 text-white"
              : "bg-gray-100 text-gray-700 hover:bg-gray-200";
            return (
              <div
                key={label as string}
                className={`rounded w-1/4 py-3 select-none cursor-pointer transition-colors flex justify-center items-center ${selectedStyle}`}
                onClickCapture={(e) => {
                  e.preventDefault();
                  setKind(kindId as EntityKind);
                }}
              >
                <FontAwesomeIcon
                  icon={icon as IconDefinition}
                  className="mr-2"
                />
                {label as string}
              </div>
            );
          })}
        </div>
      </div>
      <div className="space-y-2 mt-4 flex flex-col items-center">{form}</div>
    </div>
  );
});

function CreateMovieForm({
  movie,
  setMovie,
  imported,
}: {
  movie: MovieInputType;
  setMovie: Dispatch<SetStateAction<MovieInputType>>;
  imported: ImportedResult | null;
}): JSX.Element {
  return (
    <>
      <FormControl
        id="name"
        label="电影名"
        value={movie.name ?? imported?.title ?? ""}
        twoline
        width="w-128"
        onChange={(v) => {
          setMovie((m) => ({ ...m, name: v }));
        }}
        reset={imported?.title != null && movie.name !== undefined}
        onReset={() => {
          setMovie((m) => ({ ...m, name: undefined }));
        }}
      />
      <FormControl
        id="original_name"
        label="原名"
        value={movie.original_name ?? imported?.title ?? ""}
        twoline
        width="w-128"
        onChange={(v) => {
          setMovie((m) => ({ ...m, original_name: v }));
        }}
        reset={imported?.title != null && movie.original_name !== undefined}
        onReset={() => {
          setMovie((m) => ({ ...m, original_name: undefined }));
        }}
      />
      <ChipsInput
        id="directors"
        label="导演"
        value={movie.directors ?? imported?.directors ?? []}
        twoline
        width="w-128"
        onChange={(v) => {
          setMovie((m) => ({ ...m, directors: v }));
        }}
        reset={imported?.directors != null && movie.directors !== undefined}
        onReset={() => {
          setMovie((m) => ({ ...m, directors: undefined }));
        }}
      />
      <ChipsInput
        id="actors"
        label="主演"
        value={movie.actors ?? imported?.actors ?? []}
        twoline
        width="w-128"
        onChange={(v) => {
          setMovie((m) => ({ ...m, actors: v }));
        }}
        reset={imported?.actors != null && movie.actors !== undefined}
        onReset={() => {
          setMovie((m) => ({ ...m, actors: undefined }));
        }}
      />
      <ChipsInput
        id="genres"
        label="类型"
        value={movie.genres ?? imported?.genres ?? []}
        twoline
        width="w-128"
        onChange={(v) => {
          setMovie((m) => ({ ...m, genres: v }));
        }}
        reset={imported?.genres != null && movie.genres !== undefined}
        onReset={() => {
          setMovie((m) => ({ ...m, genres: undefined }));
        }}
      />
      <ChipsInput
        id="countries"
        label="制片国家/地区"
        value={movie.countries ?? []}
        twoline
        width="w-128"
        onChange={(v) => {
          setMovie((m) => ({ ...m, countries: v }));
        }}
      />
      <ChipsInput
        id="languages"
        label="语言"
        value={movie.languages ?? []}
        twoline
        width="w-128"
        onChange={(v) => {
          setMovie((m) => ({ ...m, languages: v }));
        }}
      />
      <ChipsInput
        id="aliases"
        label="别名"
        value={movie.aliases ?? []}
        twoline
        width="w-128"
        onChange={(v) => {
          setMovie((m) => ({ ...m, aliases: v }));
        }}
      />
      <FormControl
        id="release_date"
        label="上映日期"
        value={movie.release_date ?? imported?.release_date ?? ""}
        twoline
        width="w-128"
        onChange={(v) => {
          setMovie((m) => ({ ...m, release_date: v }));
        }}
        reset={
          imported?.release_date != null && movie.release_date !== undefined
        }
        onReset={() => {
          setMovie((m) => ({ ...m, release_date: undefined }));
        }}
      />
      <FormTextarea
        id="description"
        label="简介"
        value={movie.description ?? imported?.description ?? ""}
        twoline
        width="w-128"
        onChange={(v) => {
          setMovie((m) => ({ ...m, description: v }));
        }}
        reset={imported?.description != null && movie.description !== undefined}
        onReset={() => {
          setMovie((m) => ({ ...m, description: undefined }));
        }}
      />
    </>
  );
}

import React, { useEffect, useState, type ForwardedRef, useMemo } from "react";
import { useFragment, useLazyLoadQuery, useMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpinner,
  faStar,
  faStarHalfStroke,
} from "@fortawesome/free-solid-svg-icons";
import { faStar as faStarOutline } from "@fortawesome/free-regular-svg-icons";
import { Blurhash } from "react-blurhash";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal,
  Skeleton,
} from "@mui/material";
import VisibilitySelect from "@components/VisibilitySelect";
import RatingSelect from "@components/RatingSelect";
import { type Visibility } from "@utilities/Mastodon";
import filterNonNulls from "@utilities/filterNonNulls";
import { useSnackbar } from "notistack";
import { useRecoilState, useRecoilValue } from "recoil";
import { TwoColumn, Aside, Main } from "@components/Layout";
import { faMastodon } from "@fortawesome/free-brands-svg-icons";
import { type MoviePageQuery } from "./__generated__/MoviePageQuery.graphql";
import "./MoviePage.css";
import {
  type MarkEntityInput,
  type MoviePageMarkEntityMutation,
} from "./__generated__/MoviePageMarkEntityMutation.graphql";
import { type MoviePageExternalLinkCard_link$key } from "./__generated__/MoviePageExternalLinkCard_link.graphql";
import { type MoviePageMarkHistory$key } from "./__generated__/MoviePageMarkHistory.graphql";
import { type MoviePageTopComments$key } from "./__generated__/MoviePageTopComments.graphql";
import { type MoviePageDeleteMarkMutation } from "./__generated__/MoviePageDeleteMarkMutation.graphql";
import { HasAccount, ShareToMastodon } from "../../State";
import humanizeVerb from "../../utilities/humanizeVerb";

interface Props {}

export function DataLine({
  label,
  value,
  className = "",
}: {
  label: string;
  value: string;
  className?: string;
}): JSX.Element {
  if (value === "") {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }
  return (
    <div className={`flex flex-col w-1/3 ${className ?? ""}`}>
      <span className="text-gray-600 shrink-0 text-xs font-medium">
        {label}
      </span>
      <span className="mb-2 text-gray-800 dark:text-gray-200">{value}</span>
    </div>
  );
}

function MarkAction({
  action,
  onClick,
}: {
  action: string;
  onClick: () => void;
}): JSX.Element {
  return (
    <button
      type="button"
      className="shadow-md text-blue-100 bg-blue-500 text-xs backdrop-blur rounded flex-1 py-2 hover:bg-opacity-80 hover:text-white hover:bg-blue-400 transition font-semibold"
      onClick={onClick}
    >
      {action}
    </button>
  );
}

interface Entity {
  id: string;
  name: string | undefined;
  original_name: string | undefined;
  cover_url: string | undefined;
}

// eslint-disable-next-line react/display-name
export const MarkActionForm = React.forwardRef(
  (
    {
      entity,
      selected: initSelected,
      onFinished,
      markHistoryConnectionId = null,
      ...other
    }: {
      entity: Entity;
      selected: string;
      onFinished: () => void;
      // eslint-disable-next-line react/require-default-props
      markHistoryConnectionId?: string | null;
    },
    ref: ForwardedRef<HTMLFormElement>
  ) => {
    const [selected, setSelected] = React.useState(initSelected);
    const [visibility, setVisibility] = React.useState<Visibility>("public");
    const [rating, setRating] = React.useState(0);
    const [message, setMessage] = React.useState("");
    const [shareToMastodon, setShareToMastodon] =
      useRecoilState(ShareToMastodon);
    const showRating = selected !== "想看";
    const { enqueueSnackbar } = useSnackbar();

    const [mark, markInFlight] =
      useMutation<MoviePageMarkEntityMutation>(graphql`
        mutation MoviePageMarkEntityMutation(
          $input: MarkEntityInput!
          $connections: [ID!]!
        ) {
          mark_entity(input: $input) {
            __typename
            ... on MarkEntityPayload {
              activity
                @prependNode(
                  connections: $connections
                  edgeTypeName: "ActivityEdge"
                ) {
                id
                verb
                comment
                rating
                created_at
              }
            }
            ... on Error {
              message
            }
          }
        }
      `);

    return (
      <form
        ref={ref}
        className="card absolute w-[700px] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 overflow-hidden shadow-xl"
        onSubmitCapture={(e) => {
          e.preventDefault();
          if (markInFlight) {
            return;
          }
          let verb = "WISHLIST" as MarkEntityInput["verb"];
          if (selected === "想看") {
            verb = "WISHLIST";
          } else if (selected === "在看") {
            verb = "IN_PROGRESS";
          } else if (selected === "看过") {
            verb = "FINISHED";
          }
          mark({
            variables: {
              input: {
                id: entity.id,
                verb,
                rating,
                message,
                visibility:
                  visibility.toUpperCase() as MarkEntityInput["visibility"],
                share_to_mastodon: shareToMastodon,
              },
              connections:
                markHistoryConnectionId != null
                  ? [markHistoryConnectionId]
                  : [],
            },
            onError: (error) => {
              enqueueSnackbar(`条目收藏失败：${error.message}`, {
                variant: "error",
              });
            },
            onCompleted: (result) => {
              // eslint-disable-next-line no-underscore-dangle
              if (result.mark_entity.__typename === "MarkEntityPayload") {
                onFinished();
              } else if (result.mark_entity.message != null) {
                enqueueSnackbar(`条目收藏失败：${result.mark_entity.message}`, {
                  variant: "error",
                });
              }
            },
          });
        }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...other}
      >
        <div className="bg-gray-100 dark:bg-gray-900 flex">
          <div className="w-1/3 p-5 pb-3">
            <img
              src={entity.cover_url}
              alt={entity.name}
              className="shadow-lg rounded"
            />
          </div>
          <div className="w-2/3 pt-5 pr-5 pb-3 flex flex-col">
            <h2 className="text-2xl font-zh-serif font-semibold mb-1">
              {entity.name}
            </h2>
            <h2 className="text-xs text-gray-700 dark:text-gray-300 mb-1">
              {entity.original_name}
            </h2>
            <div className="flex items-center justify-between mt-2">
              <div className="">
                {["想看", "在看", "看过"].map((action) => (
                  <button
                    key={action}
                    className={`bg-blue-200 px-4 py-2 first:rounded-l last:rounded-r text-blue-700 action-button text-xs ${
                      action === selected ? "selected" : ""
                    }`}
                    type="button"
                    onClickCapture={() => {
                      setSelected(action);
                    }}
                  >
                    {action}
                  </button>
                ))}
              </div>
              <RatingSelect
                className={`ml-3 transition duration-300 opacity-0 ${
                  showRating ? "opacity-100" : "invisible"
                }`}
                value={rating}
                onChange={setRating}
              />
            </div>
            <div className="flex-1 mt-3">
              <textarea
                className="w-full h-full rounded-lg border border-gray-300 bg-gray-50 dark:border-gray-700 dark:bg-gray-800 px-3 py-2 resize-none"
                placeholder="写点评语吧"
                value={message}
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="flex items-end justify-end px-5 pb-3 bg-gray-100 dark:bg-gray-900">
          <div className="w-8 h-8 flex items-center justify-center mr-2">
            <button
              type="button"
              className={`w-5 h-5 ${
                shareToMastodon
                  ? " text-blue-600 dark:text-blue-500"
                  : " text-gray-300 dark:text-gray-700"
              }`}
              onClickCapture={() => {
                setShareToMastodon(!shareToMastodon);
              }}
              title="分享到长毛象"
            >
              <FontAwesomeIcon
                icon={faMastodon}
                className="w-5 h-5 transition-colors"
              />
            </button>
          </div>
          <VisibilitySelect
            value={visibility}
            onChange={setVisibility}
            className="mr-6"
          />
          <button
            type="submit"
            className="rounded bg-orange-600 text-white px-6 py-2"
            disabled={markInFlight}
          >
            {markInFlight ? <FontAwesomeIcon icon={faSpinner} spin /> : "收藏"}
          </button>
        </div>
      </form>
    );
  }
);

export function MarkActionBar({
  entity,
  markHistoryConnectionId = null,
}: {
  entity: Entity;
  markHistoryConnectionId?: string | null;
}): JSX.Element {
  const [showModal, setShowModal] = React.useState(false);
  const [selected, setSelected] = React.useState("想看");

  return (
    <div className="mt-4 flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2">
      <MarkAction
        action="想看"
        onClick={() => {
          setSelected("想看");
          setShowModal(true);
        }}
      />
      <MarkAction
        action="在看"
        onClick={() => {
          setSelected("在看");
          setShowModal(true);
        }}
      />
      <MarkAction
        action="看过"
        onClick={() => {
          setSelected("看过");
          setShowModal(true);
        }}
      />
      <Modal
        open={showModal}
        onClose={() => {
          setShowModal(false);
        }}
        // keepMounted
      >
        <MarkActionForm
          entity={entity}
          selected={selected}
          onFinished={() => {
            setShowModal(false);
          }}
          markHistoryConnectionId={markHistoryConnectionId}
        />
      </Modal>
    </div>
  );
}

export function calculateRating(rating: number): [number, number, number] {
  const soild = Math.floor(rating / 20);
  const remain = rating % 20;

  if (remain >= 15) {
    return [soild + 1, 0, 5 - soild - 1];
  }
  if (remain >= 5) {
    return [soild, 1, 5 - soild - 1];
  }
  return [soild, 0, 5 - soild];
}

export function Rating({ rating }: { rating: number }): JSX.Element {
  const [soild, half, empty] = calculateRating(rating);
  return (
    <div className="text-orange-600 flex">
      <div className="text-lg">
        {Array.from({ length: soild }, (_, i) => (
          <FontAwesomeIcon key={`soild-${i}`} icon={faStar} />
        ))}
        {Array.from({ length: half }, (_, i) => (
          <FontAwesomeIcon key={`half-${i}`} icon={faStarHalfStroke} />
        ))}
        {Array.from({ length: empty }, (_, i) => (
          <FontAwesomeIcon key={`empty-${i}`} icon={faStarOutline} />
        ))}
      </div>
      <span className="ml-2 font-extrabold font-rounded text-xl select-none">
        {(rating / 10).toFixed(1)}
      </span>
    </div>
  );
}

export function MovieCard({
  movie,
  markHistoryConnectionId,
}: {
  movie: NonNullable<MoviePageQuery["response"]["get_entity"]>;
  markHistoryConnectionId: string | null;
}): JSX.Element {
  if (movie.id === undefined) {
    return <>invalid</>;
  }
  const hasAccount = useRecoilValue(HasAccount);
  return (
    <div className="flex card overflow-hidden mt-2">
      <div className="w-1/3 shrink-0 relative">
        <div className="absolute top-0 left-0 bottom-0 right-0 z-0">
          <Blurhash
            hash={movie.blurhash ?? "U3P6~x~qfQ~q~qj[fQj[fQfQfQfQ~qj[fQj["}
            width="100%"
            height="100%"
          />
        </div>
        <div className="relative z-50 flex flex-col px-8 py-5">
          <div className="rounded overflow-hidden shadow-lg select-none">
            {movie.cover_url === undefined ? (
              <Skeleton variant="rounded" width={200} height={230} />
            ) : (
              <img src={movie.cover_url} alt="poster" />
            )}
          </div>
          {hasAccount && (
            <MarkActionBar
              entity={{
                id: movie.id ?? "",
                name: movie.name,
                original_name: movie.original_name ?? undefined,
                cover_url: movie.cover_url,
              }}
              markHistoryConnectionId={markHistoryConnectionId}
            />
          )}
        </div>
      </div>
      <div className="py-5 px-4 w-2/3 flex flex-wrap justify-start content-start">
        <DataLine label="国家" value={(movie.countries ?? []).join(" / ")} />
        <DataLine label="类型" value={(movie.genres ?? []).join(" / ")} />
        <DataLine label="语言" value={(movie.languages ?? []).join(" / ")} />
        <DataLine label="上映日期" value={movie.release_date ?? ""} />
        <DataLine
          label="导演"
          value={(movie.director ?? []).join(" / ")}
          className="w-full"
        />
        <DataLine
          label="主演"
          value={(movie.actors ?? []).join(" / ")}
          className="w-full"
        />
        <DataLine
          label="别名"
          value={(movie.aliases ?? []).join(" / ")}
          className="w-full"
        />
        {hasAccount && (
          <Link
            to={`/movie/${movie.id}/edit`}
            className=" text-orange-800 text-xs hover:underline select-none"
          >
            编辑条目
          </Link>
        )}
      </div>
    </div>
  );
}

function ExternalLinkCard({
  query,
}: {
  query: MoviePageExternalLinkCard_link$key;
}): JSX.Element {
  const { links } = useFragment(
    graphql`
      fragment MoviePageExternalLinkCard_link on Movie {
        links(first: 20) @connection(key: "Movie_links") {
          edges {
            node {
              favicon
              name
              url
            }
          }
        }
      }
    `,
    query
  );
  const data = filterNonNulls(links?.edges ?? []);

  return (
    <div className="mt-4 card overflow-hidden">
      <h2 className="card-title">相关链接</h2>
      <div className="space-y-0">
        {data.map(({ node: link }) => (
          <a
            key={`kind-${link.name}`}
            className="flex px-4 py-3 items-center select-none hover:bg-gray-100 dark:hover:bg-gray-700"
            href={link.url}
            target="_blank"
            rel="noreferrer"
          >
            <img src={link.favicon} alt="douban icon" className="w-5 h-5" />
            <div className="flex flex-col text-xs ml-2 overflow-hidden">
              <span className="font-bold">{link.name}</span>
              <span className="text-gray-400 whitespace-nowrap overflow-ellipsis overflow-hidden">
                {link.url}
              </span>
            </div>
          </a>
        ))}
      </div>
    </div>
  );
}

function MarkHistory({
  query,
  setMarkHistoryConnectionId,
}: {
  query: MoviePageMarkHistory$key;
  setMarkHistoryConnectionId: (id: string | null) => void;
}): JSX.Element {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { mark_history } = useFragment(
    graphql`
      fragment MoviePageMarkHistory on Movie {
        mark_history(first: 5)
          @connection(key: "MoviePageMarkHistory_mark_history") {
          __id
          edges {
            node {
              id
              verb
              comment
              rating
              created_at
            }
          }
        }
      }
    `,
    query
  );
  useEffect(() => {
    // eslint-disable-next-line no-underscore-dangle
    setMarkHistoryConnectionId(mark_history?.__id ?? null);
    // eslint-disable-next-line no-underscore-dangle
  }, [setMarkHistoryConnectionId, mark_history?.__id]);
  const [deleteMark, deleteMarkInFlight] =
    useMutation<MoviePageDeleteMarkMutation>(graphql`
      mutation MoviePageDeleteMarkMutation($id: ID!) {
        delete_mark(id: $id) {
          __typename
          ... on Success {
            message
          }
          ... on Error {
            message
          }
        }
      }
    `);
  const data = filterNonNulls(mark_history?.edges ?? []);
  const [recordToDelete, setRecordToDelete] = useState<
    (typeof data)[0]["node"] | null
  >(null);
  const [showDeletionConfirm, setShowDeletionConfirm] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  return (
    <div className="mt-4 card overflow-hidden">
      <h2 className="card-title">收藏历史</h2>
      <div className="space-y-0">
        {data.length === 0 && (
          <div className="text-center text-gray-600 text-xs py-3">
            还未收藏过此条目
          </div>
        )}
        {data.map(({ node: record }) => (
          <div
            className="text-xs border-t border-gray-100 py-3 px-4 hover:bg-gray-100 first:border-0 dark:border-gray-900 dark:hover:bg-gray-700"
            key={record.id}
          >
            <div className="flex justify-between">
              <span className="font-bold text-orange-700">
                {humanizeVerb(record.verb, "看")}
                {record.rating !== null && (
                  <RatingSelect
                    value={record.rating}
                    readonly
                    className="ml-1 inline text-xs space-x-0"
                  />
                )}
              </span>
              <div className="text-gray-500 select-none">
                {/* TODO: 编辑收藏条目 */}
                {/* <a href="/" className="hover:underline">
                    编辑
                  </a> */}
                {/* <span className="text-gray-200 mx-2">|</span> */}
                <button
                  type="button"
                  className="hover:underline"
                  onClick={() => {
                    setRecordToDelete(record);
                    setShowDeletionConfirm(true);
                  }}
                >
                  删除
                </button>
              </div>
            </div>
            <div className="my-1 text-sm">
              <p>{record.comment}</p>
            </div>
            <span className="text-gray-500 mr-2">{record.created_at}</span>
          </div>
        ))}
        {/* TODO: 展开收藏历史 */}
        {/* <div className="bg-gray-200 text-center text-xs font-bold py-2 text-gray-800 select-none hover:bg-gray-300 cursor-pointer">
            展开全部
          </div> */}
      </div>
      <Dialog
        open={showDeletionConfirm}
        onClose={() => {
          setShowDeletionConfirm(false);
        }}
        keepMounted
      >
        <DialogTitle className="w-96">确定删除这条收藏？</DialogTitle>
        <DialogContent>
          {recordToDelete != null && (
            <div className="rounded-lg bg-gray-50 p-4 flex flex-col text-xs">
              <div className="font-bold text-orange-700">
                {humanizeVerb(recordToDelete.verb, "看")}{" "}
                {recordToDelete.rating !== null && (
                  <RatingSelect
                    value={recordToDelete.rating}
                    readonly
                    className="ml-1 inline text-xs space-x-0"
                  />
                )}
              </div>
              <div className="my-1 text-sm">
                <p>{recordToDelete.comment}</p>
              </div>
              <span className="text-gray-500 mr-2">
                {recordToDelete.created_at}
              </span>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            onClick={(e) => {
              e.preventDefault();
              if (deleteMarkInFlight) return;
              if (recordToDelete == null) return;
              deleteMark({
                variables: {
                  id: recordToDelete.id,
                },
                onError: (error) => {
                  enqueueSnackbar(`收藏删除失败：${error.message}`, {
                    variant: "error",
                  });
                },
                onCompleted: (result) => {
                  // eslint-disable-next-line no-underscore-dangle
                  if (result.delete_mark.__typename === "Success") {
                    setShowDeletionConfirm(false);
                    setRecordToDelete(null);
                  } else if (result.delete_mark.message != null) {
                    enqueueSnackbar(
                      `收藏删除失败：${result.delete_mark.message}`,
                      { variant: "error" }
                    );
                  }
                },
              });
            }}
          >
            删除
          </Button>
          <Button
            onClick={() => {
              setShowDeletionConfirm(false);
            }}
          >
            取消
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function TopCommentCard({
  query,
}: {
  query: MoviePageTopComments$key;
}): JSX.Element {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { top_comments } = useFragment(
    graphql`
      fragment MoviePageTopComments on Movie {
        top_comments {
          edges {
            node {
              id
              verb
              comment
              rating
              created_at
              user {
                avatar
                username
                display_name
              }
            }
          }
        }
      }
    `,
    query
  );
  const data = filterNonNulls(top_comments?.edges ?? []);
  return (
    <div className="mt-4 card overflow-hidden">
      <h2 className="card-title">最近评论</h2>
      <div className="space-y-0">
        {data.length === 0 && (
          <div className="text-center text-gray-600 text-xs py-5">
            还没有人公开评论过此条目
          </div>
        )}
        {data.map(({ node: record }) => (
          <div
            className="text-xs group border-t border-gray-100 py-3 px-4 flex first:border-0 dark:border-gray-900"
            key={`${record.created_at}`}
          >
            <div className="flex-0 mr-2">
              {(record.user?.avatar ?? null) != null ? (
                <img
                  src={record.user?.avatar ?? ""}
                  alt="avatar"
                  className="w-10 h-10 rounded"
                />
              ) : (
                <Skeleton variant="rounded" width={44} height={44} />
              )}
            </div>
            <div className="flex-1">
              <div className="flex justify-between">
                <span className="">
                  <a href="/" className="font-bold text-orange-800">
                    {record.user?.display_name}
                  </a>
                  <span className="ml-2 text-gray-700">
                    {humanizeVerb(record.verb, "看")}
                  </span>
                  {record.rating !== null && (
                    <RatingSelect
                      value={record.rating}
                      readonly
                      className="ml-1 inline text-xs space-x-0"
                    />
                  )}
                </span>
                <span className="text-gray-500">{record.created_at}</span>
              </div>
              <div className="mt-1 text-sm">
                <p>{record.comment}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default function MoviePage(_: Props): JSX.Element {
  const { id } = useParams();

  if (id == null) {
    return <div className="flex mb-24">Not found</div>;
  }

  const entry = useLazyLoadQuery<MoviePageQuery>(
    graphql`
      query MoviePageQuery($id: ID!) {
        get_entity(id: $id) {
          ... on Movie {
            id
            name
            original_name
            cover_url
            blurhash
            douban_rating
            director
            actors
            genres
            countries
            languages
            aliases
            release_date
            description

            ...MoviePageExternalLinkCard_link
            ...MoviePageMarkHistory
            ...MoviePageTopComments
          }
        }
      }
    `,
    { id }
  );

  const [markHistoryConnectionId, setMarkHistoryConnectionId] = useState<
    string | null
  >(null);

  if (entry.get_entity == null) {
    return <div className="flex mb-24">Not found</div>;
  }

  const movie = entry.get_entity;

  useEffect(() => {
    const name = movie?.name ?? movie?.original_name ?? "";
    const year = movie?.release_date?.split("-")[0] ?? "";
    if (year.length !== 0) {
      document.title = `${name} (${year}) | 贝塔镇`;
    } else {
      document.title = `${name} | 贝塔镇`;
    }
  });

  const hasAccount = useRecoilValue(HasAccount);

  return (
    <TwoColumn className="mb-24">
      <Main className="flex flex-col">
        <div className="flex flex-col lg:flex-row justify-between mb-4">
          <div>
            <h1 className="font-zh-serif text-3xl font-bold text-black dark:text-gray-50">
              {movie.name}
            </h1>
            <h2 className="text-gray-600 dark:text-gray-300">
              {movie.original_name}
            </h2>
          </div>
          <div className="flex lg:justify-center items-center">
            <Rating rating={movie.douban_rating ?? 0} />
          </div>
        </div>
        <MovieCard
          movie={movie}
          markHistoryConnectionId={markHistoryConnectionId}
        />
        <div className="mt-4 card">
          <h2 className="card-title">电影介绍</h2>
          <div className="p-4">
            {movie.description === "" ? "暂无简介" : movie.description}
          </div>
        </div>
        <TopCommentCard query={movie} />
      </Main>
      <Aside>
        <ExternalLinkCard query={movie} />
        {hasAccount && (
          <MarkHistory
            query={movie}
            setMarkHistoryConnectionId={setMarkHistoryConnectionId}
          />
        )}
      </Aside>
    </TwoColumn>
  );
}

/**
 * @generated SignedSource<<b6220b63f78a9c14c4854787f8562b09>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ActivityVerb = "FINISHED" | "IN_PROGRESS" | "WISHLIST" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type MoviePageTopComments$data = {
  readonly top_comments: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly comment: string;
        readonly created_at: string;
        readonly id: string;
        readonly rating: number | null;
        readonly user: {
          readonly avatar: string | null;
          readonly display_name: string;
          readonly username: string;
        };
        readonly verb: ActivityVerb;
      };
    } | null> | null;
  } | null;
  readonly " $fragmentType": "MoviePageTopComments";
};
export type MoviePageTopComments$key = {
  readonly " $data"?: MoviePageTopComments$data;
  readonly " $fragmentSpreads": FragmentRefs<"MoviePageTopComments">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MoviePageTopComments",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ActivityConnection",
      "kind": "LinkedField",
      "name": "top_comments",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ActivityEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Activity",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "verb",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "comment",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "rating",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "created_at",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "User",
                  "kind": "LinkedField",
                  "name": "user",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "avatar",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "username",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "display_name",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Movie",
  "abstractKey": null
};

(node as any).hash = "e4ccaafd30d06457d26f909aa9b6d289";

export default node;

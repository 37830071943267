/* eslint-disable jsx-a11y/label-has-associated-control */
import {
  faCircleInfo,
  faSpinner,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useMemo } from "react";
import { useMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import { useRecoilValue } from "recoil";
import EnvironmentBanner from "../components/EnvironmentBanner";
import logo from "../logo.svg";
import township from "./township.png";
import { type LandingPageLoginMutation } from "./__generated__/LandingPageLoginMutation.graphql";
import { Messages } from "../State";

interface Props {}

function Alert({
  kind,
  children,
  className = "",
}: {
  kind: "info" | "error";
  children: React.ReactNode;
  className?: React.HTMLAttributes<HTMLDivElement>["className"];
}): JSX.Element {
  let icon;
  let color = "";

  switch (kind) {
    case "error":
      icon = faXmark;
      color = "text-red-600 bg-red-100 border-red-500";
      break;
    case "info":
    default:
      icon = faCircleInfo;
      color = "text-sky-600 bg-sky-100 border-sky-500";
      break;
  }

  return (
    <div className={`border rounded p-4 mb-4 ${color} ${className ?? ""}`}>
      <FontAwesomeIcon icon={icon} />
      <span className="ml-2">{children}</span>
    </div>
  );
}

export default function LandingPage(_: Props): JSX.Element {
  const [instance, setInstance] = React.useState("");
  const messages = useRecoilValue(Messages);

  const messageConcated = useMemo(() => {
    if (messages === null || messages.length === 0) return null;

    return messages.map(({ message }) => message).join("；");
  }, [messages]);

  const [error, setError] = React.useState(messageConcated ?? "");

  const [login, loginInFlight] = useMutation<LandingPageLoginMutation>(graphql`
    mutation LandingPageLoginMutation($instance: String!) {
      login_with(instance: $instance) {
        url
        error
      }
    }
  `);

  return (
    <div>
      <EnvironmentBanner />
      <div className="w-[800px] mx-auto mt-24">
        <Alert kind="info">
          贝塔镇目前仍在施工中，欢迎加入我们的社区{" "}
          <a
            href="https://discord.gg/vqxavw5EQd"
            target="_blank"
            className="underline"
            rel="noreferrer"
          >
            Discord
          </a>{" "}
          了解最新进度，感谢关注！
        </Alert>
        <div className="flex h-[500px] shadow-card bg-white rounded-lg overflow-hidden select-none">
          <div
            className="w-1/2 block bg-cover"
            style={{ backgroundImage: `url(${township})` }}
          />
          <div className="w-1/2 flex flex-col justify-center">
            <div className="flex justify-center ">
              <img src={logo} className="" alt="logo" />
            </div>
            <form
              className=" px-4 mt-8 py-8 flex flex-col items-center"
              onSubmit={(e) => {
                e.preventDefault();
                if (loginInFlight) return;
                const url = instance === "" ? "mastodon.social" : instance;
                login({
                  variables: {
                    instance: url,
                  },
                  onError: (err) => {
                    setError("应用错误");
                    // eslint-disable-next-line no-console
                    console.error(err);
                  },
                  // eslint-disable-next-line @typescript-eslint/naming-convention
                  onCompleted: ({ login_with }) => {
                    if (login_with == null) {
                      setError("未知错误");
                    }
                    if (login_with.error != null) {
                      setError(login_with.error);
                      return;
                    }
                    if (login_with.url == null) {
                      setError("应用错误：服务器返回了空的登入地址");
                      return;
                    }
                    window.location.href = login_with.url;
                  },
                });
              }}
            >
              {error !== "" && (
                <Alert kind="error" className="w-4/5 py-2 px-3 text-xs">
                  {error}
                </Alert>
              )}
              <label
                htmlFor="instance"
                className="w-4/5 block text-xs font-bold text-gray-500"
              >
                长毛象实例地址
              </label>
              <input
                type="text"
                id="instance"
                name="instance"
                placeholder="mastodon.social"
                className="w-4/5 border border-gray-200 rounded-lg h-10 px-3 py-1 bg-white my-4 shadow-card"
                value={instance}
                onChange={(e) => {
                  setInstance(e.target.value);
                }}
              />
              <button
                type="submit"
                className="w-4/5 bg-orange-500 hover:bg-orange-600 transition text-white rounded h-10"
                disabled={loginInFlight}
              >
                {loginInFlight ? (
                  <FontAwesomeIcon icon={faSpinner} spin />
                ) : (
                  "登入"
                )}
              </button>
              <div className="mt-4 text-xs text-gray-500">
                <a
                  href="https://fanzeyi.notion.site/398610be5ef6413a8ea3e9b1be273fde"
                  target="_blank"
                  rel="noreferrer"
                >
                  社区准则
                </a>
                <span className="mx-2">&middot;</span>
                <a
                  href="https://discord.gg/vqxavw5EQd"
                  target="_blank"
                  rel="noreferrer"
                >
                  Discord
                </a>
                <span className="mx-2">&middot;</span>
                <span>&hearts;</span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect } from "react";
import { useFragment, useLazyLoadQuery } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import { Link, useParams } from "react-router-dom";
import { useMastodonApi } from "@utilities/Mastodon";
import { suspend } from "suspend-react";
import AccountCard from "@components/AccountCard";
import filterNonNulls from "@utilities/filterNonNulls";
import { Aside, Main, TwoColumn } from "@components/Layout";
import { type MastodonAccount } from "../__types__/MastodonAccount";
import { type UserPageQuery } from "./__generated__/UserPageQuery.graphql";
import { type UserPage_activities$key } from "./__generated__/UserPage_activities.graphql";
import { type UserPage_activityLine$key } from "./__generated__/UserPage_activityLine.graphql";
import NoUserCabin from "./not-user-cabin.png";

interface Props {}

function ActivityLine({
  kind,
  query,
}: {
  kind: string;
  query: UserPage_activityLine$key;
}): JSX.Element {
  const { edges } = useFragment(
    graphql`
      fragment UserPage_activityLine on ActivityConnection {
        edges {
          node {
            entity {
              id
              name
              original_name
              cover_url
            }
          }
        }
      }
    `,
    query
  );
  const nodes = filterNonNulls((edges ?? []).map((edge) => edge?.node));

  if (nodes.length === 0) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }

  return (
    <div className="flex items-center py-2 select-none">
      <span className="w-12 shrink-0 text-gray-600 text-right mr-4 text-xs dark:text-gray-400">
        {kind}
      </span>
      <div className="flex space-x-2 mr-4 items-center scroll-pane">
        {nodes.map(({ entity }) => {
          let alt = entity.name;
          if (alt.length === 0) {
            alt = entity.original_name ?? "";
          }

          return (
            <div key={entity.id} className="w-24 shrink-0">
              <Link to={`/movie/${entity.id}`}>
                <img
                  src={entity.cover_url}
                  alt={alt}
                  title={alt}
                  className="rounded overflow-hidden "
                />
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
}

function ActivitySection({
  kind,
  verb,
  query,
}: {
  kind: string;
  verb: string;
  query: UserPage_activities$key;
}): JSX.Element {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { wishlist, in_progress, finished } = useFragment(
    graphql`
      fragment UserPage_activities on UserActivities {
        wishlist {
          ...UserPage_activityLine
        }
        in_progress {
          ...UserPage_activityLine
        }
        finished {
          ...UserPage_activityLine
        }
      }
    `,
    query
  );
  return (
    <div className="card pb-2">
      <div className="card-title">{kind}收藏</div>
      <ActivityLine kind={`想${verb}`} query={wishlist} />
      <ActivityLine kind={`在${verb}`} query={in_progress} />
      <ActivityLine kind={`${verb}过`} query={finished} />
    </div>
  );
}

function ActivityList({ acct }: { acct: string }): JSX.Element {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { user_activities } = useLazyLoadQuery<UserPageQuery>(
    graphql`
      query UserPageQuery($acct: String!) {
        user_activities(id: $acct) {
          __typename
          ... on UserActivitiesPayload {
            movies {
              ...UserPage_activities
            }
          }
          ... on NotFoundError {
            message
          }
        }
      }
    `,
    {
      acct,
    }
  );

  // eslint-disable-next-line no-underscore-dangle
  if (user_activities.__typename === "NotFoundError") {
    return (
      <div className="flex flex-col items-center">
        <img src={NoUserCabin} alt="not a user yet" className="w-96" />
        <span className="text-lg text-gray-600 font-bold">
          这个用户暂时还不是贝塔镇居民...
        </span>
      </div>
    );
  }
  // eslint-disable-next-line no-underscore-dangle
  if (user_activities.__typename !== "UserActivitiesPayload") {
    return <div>未知错误</div>;
  }

  return (
    <div>
      <ActivitySection kind="电影" verb="看" query={user_activities.movies} />
    </div>
  );
}

function UserPageInner({ acct }: { acct: string }): JSX.Element {
  const mastodonApi = useMastodonApi();
  const account = suspend(async () => {
    const response = await mastodonApi.lookupAccount(acct);
    return (await response.json()) as MastodonAccount;
  }, [acct]);

  useEffect(() => {
    document.title = `${account.display_name} | 贝塔镇`;
  }, [account]);

  return (
    <TwoColumn className="mb-24" asideFirst>
      <Main>
        <ActivityList acct={acct} />
      </Main>
      <Aside>
        <AccountCard
          coverImage={account.header ?? null}
          avatar={account.avatar ?? null}
          displayName={account.display_name ?? ""}
          username={acct}
          extra={account}
        />
      </Aside>
    </TwoColumn>
  );
}

export default function UserPage(_: Props): JSX.Element {
  const { acct } = useParams();
  if (acct == null || !acct?.startsWith("@")) {
    return <div>Not Found</div>;
  }

  return <UserPageInner acct={acct.slice(1)} />;
}

import React, { Suspense, useEffect, useRef } from "react";
import { useRecoilValue } from "recoil";
import useWebsocket from "react-use-websocket";
import { Skeleton } from "@mui/material";
import { useSnackbar } from "notistack";
import { useMastodonApi } from "../utilities/Mastodon";
import { CurrentUser } from "../State";
import type { MastodonStatus } from "../__types__/MastodonStatus";
import StatusComposer from "./StatusComposer";
import TimelineStatus from "../components/Status";
import type MastodonStreamEvent from "../__types__/MastodonStreamEvent";
import { useLoadMore } from "../utilities/useLoadMore";
import useTimeline from "./useTimeline";

interface Props {}

async function sleep(ms: number): Promise<void> {
  await new Promise<void>((resolve) => {
    setTimeout(resolve, ms);
  });
}

function TimelineViewInner(_: Props): JSX.Element {
  const currentUser = useRecoilValue(CurrentUser);
  const loadMoreRef = useRef<HTMLDivElement>(null);
  const { enqueueSnackbar } = useSnackbar();
  const mastodonApi = useMastodonApi();

  const [timeline, tl] = useTimeline();

  useEffect(() => {
    void (async () => {
      const result = await mastodonApi.getHomeTimeline();
      tl.setInitialTimeline((await result.json()) as MastodonStatus[]);
    })();
  }, [currentUser]);

  const [isLoading, setPauseLoading] = useLoadMore(() => {
    const lastStatus = tl.lastStatus();
    if (lastStatus == null) {
      return;
    }
    mastodonApi
      .getHomeTimeline(lastStatus.id)
      .then(async (result) => {
        const initialStatuses = (await result.json()) as MastodonStatus[];
        tl.appendStatuses(initialStatuses);
        setPauseLoading(false);
      })
      .catch((err) => {
        if (err instanceof Error) {
          enqueueSnackbar(`加载更多消息失败：${err.message}`, {
            variant: "error",
          });
        } else {
          enqueueSnackbar("加载更多消息失败", { variant: "error" });
        }
      });
  }, loadMoreRef);

  const { lastJsonMessage } = useWebsocket<MastodonStreamEvent>(
    mastodonApi.getStreamingUrl("user").toString(),
    {
      share: true,
      onOpen: () => {},
      onClose: (e) => {
        console.error("websocket closed", e.code, e.reason, e);
      },
      shouldReconnect: () => true,
      onError: (e: Event) => {
        console.error(e);
        enqueueSnackbar(`时间轴订阅出错啦`, {
          variant: "error",
        });
      },
    }
  );

  useEffect(() => {
    if (lastJsonMessage == null) {
      return;
    }
    const event = lastJsonMessage;
    if (event.event === "update") {
      const status = JSON.parse(event.payload as string) as MastodonStatus;
      tl.prependStatus(status);
    } else if (event.event === "delete") {
      const id = event.payload as string;
      tl.deleteStatus(id);
    } else if (event.event === "status.update") {
      const status = JSON.parse(event.payload as string) as MastodonStatus;
      tl.updateStatus(status.id, status);
    }
  }, [lastJsonMessage]);

  return (
    <div className="pb-20" ref={loadMoreRef}>
      {timeline.length !== 0 ? (
        timeline.map((status) => (
          <TimelineStatus
            key={status.id}
            status={status}
            updateStatus={tl.updateStatus}
            className="mb-4"
          />
        ))
      ) : (
        <TimelineViewSkeleton />
      )}
      {isLoading && <TimelineViewSkeleton />}
    </div>
  );
}

function TimelineViewSkeleton(): JSX.Element {
  return (
    <div className="flex rounded-lg shadow-card card overflow-hidden p-5">
      <div className="mr-3">
        <Skeleton variant="rounded" width={48} height={48} />
      </div>
      <div className="flex flex-col flex-1">
        <div className="flex justify-between mb-1">
          <Skeleton className="w-24" variant="text" sx={{ fontSize: "1rem" }} />
          <Skeleton className="w-14" variant="text" sx={{ fontSize: "1rem" }} />
        </div>
        <Skeleton className="w-96" variant="text" sx={{ fontSize: "1rem" }} />
        <Skeleton className="w-64" variant="text" sx={{ fontSize: "1rem" }} />
        <Skeleton className="w-48" variant="text" sx={{ fontSize: "1rem" }} />
      </div>
    </div>
  );
}

export default function TimelineView(): JSX.Element {
  return (
    <div>
      <StatusComposer />
      <Suspense fallback={<TimelineViewSkeleton />}>
        <TimelineViewInner />
      </Suspense>
    </div>
  );
}

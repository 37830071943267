/* eslint-disable @typescript-eslint/naming-convention */
import { type RecoilState, useRecoilValue } from "recoil";
import { CurrentUser } from "../State";
import { type MastodonEmoji } from "../__types__/MastodonStatus";

type TypeOf<T> = T extends RecoilState<infer U> ? U : never;

export type Visibility = "public" | "unlisted" | "private" | "direct";

export function inlineEmoji(content: string, emojis: MastodonEmoji[]): string {
  let text = content;
  emojis.forEach((emoji) => {
    const emojiImg = `<img class="emoji" draggable="false" alt="${emoji.shortcode}" src="${emoji.url}" />`;
    text = text.replace(
      new RegExp(`(^|\\>|\\s):${emoji.shortcode}:`, "g"),
      (_, left: string) => `${left}${emojiImg}`
    );
  });
  return text;
}

function addQuery(url: URL, query: Record<string, string | undefined>): URL {
  Object.entries(query).forEach(([key, value]) => {
    if (value !== undefined) {
      url.searchParams.append(key, value);
    }
  });
  return url;
}

export class MastodonApi {
  constructor(private readonly user: TypeOf<typeof CurrentUser>) {}

  getApi(method: string, args?: Record<string, string | undefined>): URL {
    let url = new URL(
      (this.user?.api_url ?? "https://mastodon.social/api/v1") + method
    );
    if (args != null) {
      url = addQuery(url, args);
    }
    return url;
  }

  getStreamingApi(method: string): URL {
    const url = this.getApi(method);
    url.protocol = "wss:";
    return url;
  }

  getStreamingUrl(stream: "user"): URL {
    let url;
    if (this.user?.stream_url != null) {
      url = new URL(this.user.stream_url);
    } else {
      url = this.getApi("/streaming");
      url.protocol = "wss:";
    }
    return addQuery(url, {
      stream,
      access_token: this.user?.access_token,
    });
  }

  async api(
    method: "GET" | "POST",
    action: string,
    {
      args,
      body,
    }: {
      args?: Record<string, string | undefined>;
      body?: Record<string, string | undefined>;
    } = {}
  ): Promise<Response> {
    const url = this.getApi(action, args ?? {});
    const headers = new Headers();
    if (this.user != null) {
      headers.append("Authorization", `Bearer ${this.user.access_token}`);
    }

    let data: FormData | null = null;
    if (body != null) {
      const formData = new FormData();
      Object.entries(body).forEach(([key, value]) => {
        if (value !== undefined) {
          formData.append(key, value);
        }
      });
      data = formData;
    }

    return await fetch(url, {
      method,
      headers,
      body: data,
    });
  }

  async reblog(id: string): Promise<Response> {
    return await this.api("POST", `/statuses/${id}/reblog`);
  }

  async unreblog(id: string): Promise<Response> {
    return await this.api("POST", `/statuses/${id}/unreblog`);
  }

  async postStatus(
    status: string,
    {
      visibility,
      in_reply_to_id,
    }: {
      visibility?: Visibility;
      in_reply_to_id?: string;
    }
  ): Promise<Response> {
    return await this.api("POST", "/statuses", {
      body: { status, visibility, in_reply_to_id },
    });
  }

  async favourite(id: string): Promise<Response> {
    return await this.api("POST", `/statuses/${id}/favourite`);
  }

  async unfavourite(id: string): Promise<Response> {
    return await this.api("POST", `/statuses/${id}/unfavourite`);
  }

  async getStatus(id: string): Promise<Response> {
    return await this.api("GET", `/statuses/${id}`);
  }

  async getStatusContext(id: string): Promise<Response> {
    return await this.api("GET", `/statuses/${id}/context`);
  }

  async getHomeTimeline(max_id?: string): Promise<Response> {
    return await this.api("GET", "/timelines/home", {
      args: { max_id },
    });
  }

  async getAccount(id: string): Promise<Response> {
    return await this.api("GET", `/accounts/${id}`);
  }

  async lookupAccount(acct: string): Promise<Response> {
    return await this.api("GET", "/accounts/lookup", {
      args: { acct },
    });
  }
}

export function useMastodonApi(): MastodonApi {
  const user = useRecoilValue(CurrentUser);

  return new MastodonApi(user);
}

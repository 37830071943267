import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

export default function RatingSelect({
  value,
  onChange = () => {},
  readonly = false,
  className = "",
}: {
  value: number;
  onChange?: (v: number) => void;
  readonly?: boolean;
  className?: React.HTMLAttributes<HTMLDivElement>["className"];
}): JSX.Element {
  const [hovering, setHovering] = React.useState(false);
  const [hoverStar, setHoverStar] = React.useState(0);

  return (
    <div
      className={`text-orange-600 text-lg space-x-1 ${className}`}
      onMouseEnter={() => {
        if (!readonly) {
          setHovering(true);
        }
      }}
      onMouseLeave={() => {
        if (!readonly) {
          setHovering(false);
        }
      }}
    >
      {Array.from({ length: 5 }, (_, i) => {
        let selected = false;
        if (hovering) {
          selected = i < hoverStar;
        } else {
          selected = i < value;
        }
        const selectedStyle = selected ? "text-orange-600" : "text-gray-300";
        const cursorStyle = readonly ? "" : "cursor-pointer";

        return (
          <FontAwesomeIcon
            key={i}
            icon={faStar}
            className={`transition-colors ${cursorStyle} ${selectedStyle}`}
            onMouseEnter={() => {
              if (!readonly) {
                setHoverStar(i + 1);
              }
            }}
            onClick={() => {
              if (!readonly) {
                onChange(i + 1);
              }
            }}
          />
        );
      })}
    </div>
  );
}

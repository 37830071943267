import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { type MastodonAccount } from "../__types__/MastodonAccount";

interface Props {
  coverImage: string | null;
  avatar: string | null;
  displayName: string;
  username: string;
  extra?: MastodonAccount | null;
}

export default function AccountCard({
  coverImage,
  avatar,
  displayName,
  username,
  extra = null,
}: Props): JSX.Element {
  const userPage = `/@${username}`;

  const pairs = useMemo(() => {
    const result = [];
    if (extra != null) {
      if (extra.created_at != null) {
        result.push({
          key: "加入于",
          value: new Date(Date.parse(extra.created_at)).toLocaleDateString(),
          verified: false,
        });
      }
      if (extra.fields != null) {
        extra.fields.forEach((field) => {
          result.push({
            key: field.name,
            value: field.value,
            verified: field.verified_at != null,
          });
        });
      }
    }
    return result;
  }, [extra]);

  return (
    <div className="card overflow-hidden flex flex-col">
      <div
        className="bg-orange-100 h-32 dark:bg-slate-700"
        style={{
          backgroundImage:
            coverImage != null ? `url(${coverImage})` : undefined,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <div className="text-center -mt-14 flex justify-center">
        <Link to={userPage}>
          <img
            className="w-28 square rounded-lg border-4 border-white"
            src={avatar ?? ""}
            alt=""
          />
        </Link>
      </div>
      <div className="text-center text-lg font-bold mt-2">
        <Link to={userPage}>{displayName}</Link>
      </div>
      <div className="text-center text-xs font-light text-gray-400 pb-6">
        <Link to={userPage}>{username}</Link>
      </div>
      {extra != null && (
        <div className="border-t border-t-gray-100 px-2 pt-4 pb-2 text-gray-600 bg-gray-50 text-xs dark:text-gray-300 dark:bg-gray-700 dark:border-gray-800">
          <div
            dangerouslySetInnerHTML={{ __html: extra.note }}
            className="px-2 pb-2 mastodon-html"
          />
          {pairs.map(({ key, value, verified }) => (
            <div
              key={key}
              className="border-t border-t-gray-100 py-2 px-2 flex flex-col dark:border-gray-800"
            >
              <strong>
                {key}
                {verified && (
                  <FontAwesomeIcon
                    icon={faCircleCheck}
                    className="ml-1 text-sky-500"
                  />
                )}
              </strong>
              <span
                className="mastodon-html"
                dangerouslySetInnerHTML={{ __html: value }}
              />
            </div>
          ))}
        </div>
      )}
      {/* <div className="text-center text-sm text-gray-500 flex justify-center">
              {[
                ["书籍", 35],
                ["电影", 82],
                ["音乐", 31],
                ["游戏", 59],
              ].map(([name, count]) => (
                <div
                  className="inline-block w-14 rounded-lg shrink-0 bg-orange-50 py-3 mx-2 my-4 select-none cursor-pointer"
                  key={name}
                >
                  <div className="font-medium">{name}</div>
                  <div className="mt-1 text-orange-600">{count}</div>
                </div>
              ))}
            </div> */}
    </div>
  );
}

/**
 * @generated SignedSource<<95c288c8d741b676c4c139b61e647964>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type MoviePageDeleteMarkMutation$variables = {
  id: string;
};
export type MoviePageDeleteMarkMutation$data = {
  readonly delete_mark: {
    readonly __typename: string;
    readonly message?: string;
  };
};
export type MoviePageDeleteMarkMutation = {
  response: MoviePageDeleteMarkMutation$data;
  variables: MoviePageDeleteMarkMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "message",
    "storageKey": null
  }
],
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "delete_mark",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": (v1/*: any*/),
        "type": "Success",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": (v1/*: any*/),
        "type": "Error",
        "abstractKey": "__isError"
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MoviePageDeleteMarkMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MoviePageDeleteMarkMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "b61a01638e2875bd110d53a4eb944f7f",
    "id": null,
    "metadata": {},
    "name": "MoviePageDeleteMarkMutation",
    "operationKind": "mutation",
    "text": "mutation MoviePageDeleteMarkMutation(\n  $id: ID!\n) {\n  delete_mark(id: $id) {\n    __typename\n    ... on Success {\n      message\n    }\n    ... on Error {\n      __isError: __typename\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d924d43987461090bae794dbb0be5b38";

export default node;

/**
 * @generated SignedSource<<fed97587192d26b0c7d66b928b506ec4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type MovieEditPageUploadCoverImageMutation$variables = {
  entity_id: string;
  new_cover: any;
};
export type MovieEditPageUploadCoverImageMutation$data = {
  readonly update_cover: {
    readonly message?: string;
    readonly updated_entity?: {
      readonly blurhash: string | null;
      readonly cover_url: string;
      readonly id: string;
    };
  };
};
export type MovieEditPageUploadCoverImageMutation = {
  response: MovieEditPageUploadCoverImageMutation$data;
  variables: MovieEditPageUploadCoverImageMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "entity_id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "new_cover"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "entity_id",
        "variableName": "entity_id"
      },
      {
        "kind": "Variable",
        "name": "new_cover",
        "variableName": "new_cover"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v2 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Movie",
      "kind": "LinkedField",
      "name": "updated_entity",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "cover_url",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "blurhash",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UpdateCoverPayload",
  "abstractKey": null
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "type": "Error",
  "abstractKey": "__isError"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MovieEditPageUploadCoverImageMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "update_cover",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MovieEditPageUploadCoverImageMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "update_cover",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "40d9717f4070b17baff0a96ed951a05e",
    "id": null,
    "metadata": {},
    "name": "MovieEditPageUploadCoverImageMutation",
    "operationKind": "mutation",
    "text": "mutation MovieEditPageUploadCoverImageMutation(\n  $entity_id: ID!\n  $new_cover: Upload!\n) {\n  update_cover(input: {entity_id: $entity_id, new_cover: $new_cover}) {\n    __typename\n    ... on UpdateCoverPayload {\n      updated_entity {\n        id\n        cover_url\n        blurhash\n      }\n    }\n    ... on Error {\n      __isError: __typename\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "fa78b2013ef023ccfd36de7189ac58df";

export default node;

import { EnvironmentKey } from "recoil-relay";
import {
  Environment,
  Network,
  RecordSource,
  Store,
  type FetchFunction,
  type GraphQLResponse,
} from "relay-runtime";

const HTTP_ENDPOINT = "/api/graphql";

const fetchFn: FetchFunction = async (
  request,
  variables,
  cacheConfig,
  uploadables
) => {
  const options: RequestInit = {
    method: "POST",
    headers: new Headers({
      Accept: "application/json",
    }),
  };

  if (uploadables != null) {
    const form = new FormData();
    form.append("query", request.text ?? "");
    form.append("variables", JSON.stringify(variables));
    Object.entries(uploadables).forEach(([key, file]) => {
      form.append(key, file);
    });
    options.body = form;
  } else {
    (options.headers as Headers).set("Content-Type", "application/json");
    options.body = JSON.stringify({
      query: request.text,
      variables,
    });
  }

  return (await (
    await fetch(HTTP_ENDPOINT, options)
  ).json()) as GraphQLResponse;
};

function createRelayEnvironment(): Environment {
  return new Environment({
    network: Network.create(fetchFn),
    store: new Store(new RecordSource()),
  });
}

export const RelayEnvironment = createRelayEnvironment();
export const RelayEnvironmentKey: EnvironmentKey = new EnvironmentKey(
  "Beta Town Environment"
);

import React, { Suspense, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { useNavigate } from "react-router-dom";
import { Aside, Main, TwoColumn } from "@components/Layout";
import { CurrentUser } from "../State";
import TimelineView from "./TimelineView";
import AccountCard from "../components/AccountCard";

interface Props {}

function Loader(): JSX.Element {
  return <div>Loading...</div>;
}

function HomePageInner(): JSX.Element {
  const currentUser = useRecoilValue(CurrentUser);
  return (
    <TwoColumn>
      <Main className="mb-4">
        <TimelineView />
      </Main>
      <Aside>
        <AccountCard
          coverImage={currentUser?.cover_image ?? null}
          avatar={currentUser?.avatar ?? null}
          displayName={currentUser?.display_name ?? ""}
          username={currentUser?.username ?? ""}
        />
        {/* <div className="rounded bg-white shadow-card overflow-hidden flex flex-col mt-4">
            <div className="font-medium text-gray-500 pt-2 pb-1 text-sm border-b mx-2 mb-2">
              收藏动态
            </div>
            <div className="flex flex-col px-2 py-1 select-none">
              {[...Array(5).keys()].map((i: number) => (
                <div className="flex justify-between mb-1" key={i}>
                  {[...Array(18).keys()].map((j: number) => (
                    <div className="w-4 h-4 bg-gray-100" key={j}>
                      &nbsp;
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div> */}
        <div className="mt-4 text-xs text-gray-500">
          <a
            href="https://fanzeyi.notion.site/398610be5ef6413a8ea3e9b1be273fde"
            target="_blank"
            rel="noreferrer"
          >
            社区准则
          </a>
          <span className="mx-2">&middot;</span>
          <a
            href="https://discord.gg/vqxavw5EQd"
            target="_blank"
            rel="noreferrer"
          >
            Discord
          </a>
          <span className="mx-2">&middot;</span>
          <span>&hearts;</span>
        </div>
      </Aside>
    </TwoColumn>
  );
}

export default function HomePage(_: Props): JSX.Element {
  const currentUser = useRecoilValue(CurrentUser);

  useEffect(() => {
    document.title = "贝塔镇";
  }, []);

  useEffect(() => {
    if (currentUser == null) {
      window.location.href = "/login";
    }
  }, [currentUser]);

  return <HomePageInner />;
}

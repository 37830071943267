import React, { Suspense } from "react";
import { Outlet } from "react-router-dom";
import EnvironmentBanner from "./components/EnvironmentBanner";
import ErrorBoundary from "./components/ErrorBoundary";
import Header from "./components/Header";

interface Props {}

export default function RootLayout(_: Props): JSX.Element {
  return (
    <ErrorBoundary>
      <Suspense>
        <div>
          <EnvironmentBanner />
          <div className="lg:w-[1040px] mx-auto">
            <Header />
            <Outlet />
          </div>
        </div>
      </Suspense>
    </ErrorBoundary>
  );
}

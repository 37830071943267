/**
 * @generated SignedSource<<9fa3ce80c8d51e097aecea4c70c67e13>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateEntityPageImportEntityMutation$variables = {
  url: string;
};
export type CreateEntityPageImportEntityMutation$data = {
  readonly import_entity: {
    readonly __typename: string;
    readonly message?: string;
    readonly path?: string;
    readonly result?: string;
  };
};
export type CreateEntityPageImportEntityMutation = {
  response: CreateEntityPageImportEntityMutation$data;
  variables: CreateEntityPageImportEntityMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "url"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "url",
            "variableName": "url"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "import_entity",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "result",
            "storageKey": null
          }
        ],
        "type": "ImportEntityPayload",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "path",
            "storageKey": null
          }
        ],
        "type": "AlreadyExists",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "type": "Error",
        "abstractKey": "__isError"
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateEntityPageImportEntityMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateEntityPageImportEntityMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "1c458d4eefda3d8388aceb0ff73cb9a0",
    "id": null,
    "metadata": {},
    "name": "CreateEntityPageImportEntityMutation",
    "operationKind": "mutation",
    "text": "mutation CreateEntityPageImportEntityMutation(\n  $url: String!\n) {\n  import_entity(input: {url: $url}) {\n    __typename\n    ... on ImportEntityPayload {\n      result\n    }\n    ... on AlreadyExists {\n      path\n    }\n    ... on Error {\n      __isError: __typename\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b41d6ae65f414d992e6f31feb0c1881e";

export default node;

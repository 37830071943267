import React, { type ForwardedRef, forwardRef, memo } from "react";
import { type CustomContentProps, SnackbarContent } from "notistack";
import {
  faCircleCheck,
  faCircleExclamation,
  faCircleInfo,
  faCircleQuestion,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type Props = CustomContentProps;

// eslint-disable-next-line prefer-arrow-callback
const Snackbar = forwardRef<HTMLDivElement, Props>(function Snackbar(
  { message, variant }: Props,
  ref: ForwardedRef<HTMLDivElement>
): JSX.Element {
  let color, icon, textColor;
  switch (variant) {
    case "error":
      color = "border-red-600";
      textColor = "text-red-600";
      icon = faCircleXmark;
      break;
    case "warning":
      color = "border-yellow-600";
      textColor = "text-yellow-700";
      icon = faCircleExclamation;
      break;
    case "success":
      color = "border-green-600";
      textColor = "text-green-700";
      icon = faCircleCheck;
      break;
    case "info":
      color = "border-sky-600";
      textColor = "text-sky-700";
      icon = faCircleInfo;
      break;
    default:
      color = "border-gray-600";
      textColor = "text-gray-700";
      icon = faCircleQuestion;
  }

  return (
    <SnackbarContent
      ref={ref}
      className={`bg-white shadow px-4 py-3 rounded border  border-l-4 ${color} ${textColor} flex items-center`}
    >
      <>
        <FontAwesomeIcon icon={icon} className="mr-2" />
        <div className="">{message}</div>
      </>
    </SnackbarContent>
  );
});

export default memo(Snackbar);

import React, {
  useCallback,
  useRef,
  useState,
  useMemo,
  useEffect,
} from "react";
import { ConnectionHandler, useLazyLoadQuery, useMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import filterNonNulls from "@utilities/filterNonNulls";
import {
  faChevronLeft,
  faCirclePlus,
  faCircleXmark as faSoildCircleXmark,
  faObjectGroup,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { useSnackbar } from "notistack";
import { useRecoilValue } from "recoil";
import { Aside, Main, TwoColumn } from "@components/Layout";
import { type MovieEditPageQuery } from "./__generated__/MovieEditPageQuery.graphql";
import ChipsInput from "../../components/ChipsInput";
import FormControl from "../../components/FormControl";
import FormTextarea from "../../components/FormTextarea";
import { type MovieEditPageUploadCoverImageMutation } from "./__generated__/MovieEditPageUploadCoverImageMutation.graphql";
import { type MovieEditPageDeleteLinkMutation } from "./__generated__/MovieEditPageDeleteLinkMutation.graphql";
import { type MovieEditPageAddLinkMutation } from "./__generated__/MovieEditPageAddLinkMutation.graphql";
import { type MovieEditPageUpdateMovieMutation } from "./__generated__/MovieEditPageUpdateMovieMutation.graphql";
import { HasAccount } from "../../State";

interface Props {}

type ExternalLink = NonNullable<
  NonNullable<
    NonNullable<
      NonNullable<MovieEditPageQuery["response"]["get_entity"]>["links"]
    >["edges"]
  >[number]
>;

export default function MovieEditPage(_: Props): JSX.Element {
  const hasAccount = useRecoilValue(HasAccount);
  const navigate = useNavigate();
  useEffect(() => {
    if (!hasAccount) {
      window.location.href = "/login";
    }
  }, [hasAccount]);
  const { id } = useParams();

  if (id == null) {
    return <div className="flex mb-24">Not found</div>;
  }
  const entry = useLazyLoadQuery<MovieEditPageQuery>(
    graphql`
      query MovieEditPageQuery($id: ID!) {
        get_entity(id: $id) {
          ... on Movie {
            id
            name
            original_name
            cover_url
            director
            actors
            genres
            countries
            languages
            aliases
            release_date
            description

            links(first: 20) @connection(key: "Movie_links") {
              edges {
                node {
                  id
                  name
                  url
                  favicon
                }
              }
            }
          }
        }
      }
    `,
    { id }
  );
  if (entry.get_entity == null) {
    return <div className="flex mb-24">Not found</div>;
  }

  const [movie, setMovie] = React.useState({
    ...entry.get_entity,
    director: [...(entry.get_entity.director ?? [])],
    actors: [...(entry.get_entity.actors ?? [])],
    genres: [...(entry.get_entity.genres ?? [])],
    countries: [...(entry.get_entity.countries ?? [])],
    languages: [...(entry.get_entity.languages ?? [])],
    aliases: [...(entry.get_entity.aliases ?? [])],
  });

  const links = filterNonNulls([...(entry.get_entity.links?.edges ?? [])]);
  const title = entry.get_entity.name ?? "";
  const coverUrl = entry.get_entity.cover_url ?? "";

  const [updateMovie, updateMovieInFlight] =
    useMutation<MovieEditPageUpdateMovieMutation>(graphql`
      mutation MovieEditPageUpdateMovieMutation($input: MovieInput!) {
        update_entity(input: { movie: $input }) {
          ... on UpdateEntityPayload {
            movie {
              id
              name
              original_name
              director
              actors
              genres
              countries
              languages
              aliases
              release_date
              description
            }
          }
          ... on Error {
            message
          }
        }
      }
    `);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const name = movie?.name ?? movie?.original_name ?? "";
    const year = movie?.release_date?.split("-")[0] ?? "";
    if (year.length !== 0) {
      document.title = `编辑「${name} (${year})」 | 贝塔镇`;
    } else {
      document.title = `编辑「${name}」 | 贝塔镇`;
    }
  });

  return (
    <TwoColumn className="flex mb-48">
      <Main>
        <form
          className="flex flex-col card space-y-4 pb-4"
          onSubmitCapture={(e) => {
            e.preventDefault();
            updateMovie({
              variables: {
                input: {
                  id: movie.id ?? "",
                  name: movie.name,
                  original_name: movie.original_name,
                  description: movie.description,
                  director: movie.director,
                  release_date: movie.release_date,
                  countries: movie.countries,
                  aliases: movie.aliases,
                  actors: movie.actors,
                  genres: movie.genres,
                  languages: movie.languages,
                },
              },
              onCompleted: (result) => {
                if (result.update_entity?.message != null) {
                  enqueueSnackbar(
                    `条目编辑失败：${result.update_entity.message}`,
                    {
                      variant: "error",
                    }
                  );
                } else {
                  enqueueSnackbar("条目编辑成功", { variant: "success" });
                  navigate(`/movie/${id}`);
                }
              },
              onError: (error) => {
                enqueueSnackbar(`条目编辑失败：${error.message}`, {
                  variant: "error",
                });
              },
            });
          }}
        >
          <div className="card-title flex">
            <div className="pr-2 text-orange-700 border-r border-gray-100 mr-2 font-normal hover:text-orange-800">
              <Link to={`/movie/${id}`}>
                <FontAwesomeIcon
                  icon={faChevronLeft}
                  className="mr-1 text-xs"
                />
                返回条目
              </Link>
            </div>
            <div>{title.length > 0 ? `编辑《${title}》` : "编辑条目"}</div>
          </div>
          <FormControl
            id="name"
            label="电影名"
            value={movie.name ?? ""}
            onChange={(v) => {
              setMovie((m) => ({ ...m, name: v }));
            }}
          />
          <FormControl
            id="original_name"
            label="原名"
            value={movie.original_name ?? ""}
            onChange={(v) => {
              setMovie((m) => ({ ...m, original_name: v }));
            }}
          />
          <ChipsInput
            id="directors"
            label="导演"
            value={movie.director ?? []}
            onChange={(v) => {
              setMovie((m) => ({ ...m, director: v }));
            }}
          />
          <ChipsInput
            id="actors"
            label="主演"
            value={movie.actors ?? []}
            onChange={(v) => {
              setMovie((m) => ({ ...m, actors: v }));
            }}
          />
          <ChipsInput
            id="genres"
            label="类型"
            value={movie.genres ?? []}
            onChange={(v) => {
              setMovie((m) => ({ ...m, genres: v }));
            }}
          />
          <ChipsInput
            id="countries"
            label="制片国家/地区"
            value={movie.countries ?? []}
            onChange={(v) => {
              setMovie((m) => ({ ...m, countries: v }));
            }}
          />
          <ChipsInput
            id="languages"
            label="语言"
            value={movie.languages ?? []}
            onChange={(v) => {
              setMovie((m) => ({ ...m, languages: v }));
            }}
          />
          <ChipsInput
            id="aliases"
            label="别名"
            value={movie.aliases ?? []}
            onChange={(v) => {
              setMovie((m) => ({ ...m, aliases: v }));
            }}
          />
          <FormControl
            id="release_date"
            label="上映日期"
            value={movie.release_date ?? ""}
            onChange={(v) => {
              setMovie((m) => ({ ...m, release_date: v }));
            }}
          />
          <FormTextarea
            id="description"
            label="简介"
            value={movie.description ?? ""}
            onChange={(v) => {
              setMovie((m) => ({ ...m, description: v }));
            }}
          />
          <div className="ml-36">
            <button
              type="submit"
              className="rounded bg-orange-600 px-6 py-2 text-white font-bold"
              disabled={updateMovieInFlight}
            >
              {updateMovieInFlight ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                "编辑"
              )}
            </button>
          </div>
        </form>
      </Main>
      <Aside className="space-y-4">
        <CoverImageCard
          id={movie.id ?? id}
          coverUrl={coverUrl}
          alt={movie.name ?? ""}
        />
        <FormExternalLink entityId={movie.id ?? id} links={links} />
        <div className="flex items-center text-orange-700 pl-4">
          <Link to="/">
            <FontAwesomeIcon icon={faObjectGroup} className="mr-2 w-5" />
            合并条目
          </Link>
        </div>
      </Aside>
    </TwoColumn>
  );
}

function CoverImageCard({
  id,
  coverUrl,
  alt,
}: {
  id: string;
  coverUrl: string;
  alt: string;
}): JSX.Element {
  const inputRef = useRef<HTMLInputElement>(null);
  const [hovering, setHovering] = useState(false);
  const [showIndicator, setShowIndicator] = useState(false);
  const [dragging, setDragging] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [uploadCoverImage, uploadCoverImageInFlight] =
    useMutation<MovieEditPageUploadCoverImageMutation>(graphql`
      mutation MovieEditPageUploadCoverImageMutation(
        $entity_id: ID!
        $new_cover: Upload!
      ) {
        update_cover(input: { entity_id: $entity_id, new_cover: $new_cover }) {
          ... on UpdateCoverPayload {
            updated_entity {
              id
              cover_url
              blurhash
            }
          }
          ... on Error {
            message
          }
        }
      }
    `);

  const onChange = useCallback((file: File) => {
    if (uploadCoverImageInFlight) return;
    setShowIndicator(true);
    uploadCoverImage({
      variables: {
        entity_id: id,
        new_cover: null,
      },
      uploadables: {
        new_cover: file,
      },
      onCompleted: (data) => {
        setShowIndicator(false);
        if (data.update_cover?.message != null) {
          enqueueSnackbar(`封面上传失败：${data.update_cover.message}`, {
            variant: "error",
          });
        } else {
          enqueueSnackbar("封面上传成功！", { variant: "success" });
        }
      },
      onError: (err) => {
        setShowIndicator(false);
        enqueueSnackbar(`封面上传失败：${err.message}`, { variant: "error" });
      },
    });
  }, []);
  const hoveringClass = hovering || showIndicator ? "opacity-100" : "";

  const indicator = useMemo(() => {
    if (dragging) {
      return "松开上传";
    }
    if (uploadCoverImageInFlight) {
      return <FontAwesomeIcon icon={faSpinner} spin />;
    }
    if (hovering) {
      return "上传封面";
    }
    return "";
  }, [hovering, dragging, uploadCoverImageInFlight]);

  return (
    <div className="card">
      <div className="card-title">封面</div>
      <div
        className="p-3 relative"
        onMouseEnter={(e) => {
          e.preventDefault();
          if (!dragging) {
            setHovering(true);
          }
        }}
        onMouseLeave={(e) => {
          e.preventDefault();
          setHovering(false);
        }}
      >
        <img src={coverUrl} alt={alt} className="rounded-lg" />
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
        <div
          className={`inset-0 absolute flex items-center text-center m-3 bg-[radial-gradient(ellipse_at_center,#0000002D,#00000088)] justify-around opacity-0 transition-opacity cursor-pointer rounded-lg appearance-none ${hoveringClass}`}
          onClick={() => {
            if (uploadCoverImageInFlight) return;
            inputRef.current?.click();
          }}
          onDragOverCapture={(e) => {
            e.preventDefault();
            e.dataTransfer.dropEffect = "copy";
            setDragging(true);
            setShowIndicator(true);
          }}
          onDragLeaveCapture={(e) => {
            e.preventDefault();
            setDragging(false);
            setShowIndicator(false);
          }}
          onDropCapture={(e) => {
            e.preventDefault();
            if (uploadCoverImageInFlight) return;
            const { files } = e.dataTransfer;
            if (files.length > 0) {
              const file = files[0];
              onChange(file);
            }
            setShowIndicator(false);
            setDragging(false);
          }}
        >
          <input
            type="file"
            className="hidden"
            ref={inputRef}
            accept={["image/*"].join(",")}
            onChange={(e) => {
              const { files } = e.target;
              if (files !== null && files.length > 0) {
                const file = files[0];
                onChange(file);
              }
            }}
          />
          <div className="px-4 py-2 text-white font-bold text-lg drop-shadow">
            {indicator}
          </div>
        </div>
      </div>
    </div>
  );
}

function FormExternalLink({
  entityId,
  links,
}: {
  entityId: string;
  links: ExternalLink[];
}): JSX.Element {
  const [active, setActive] = React.useState(false);
  const [newLink, setNewLink] = React.useState<string>("");
  const showSubmitButton = active || newLink.length > 0;
  const showSubmitButtonStyle = showSubmitButton ? "opacity-100" : "";
  const linksConnection = ConnectionHandler.getConnectionID(
    entityId,
    "Movie_links"
  );
  const { enqueueSnackbar } = useSnackbar();
  const [deleteLink] = useMutation<MovieEditPageDeleteLinkMutation>(graphql`
    mutation MovieEditPageDeleteLinkMutation($id: ID!, $connections: [ID!]!) {
      delete_external_link(id: $id) {
        ... on DeleteExternalLinkPayload {
          link {
            id @deleteEdge(connections: $connections)
          }
        }
        ... on Error {
          message
        }
      }
    }
  `);
  const [addLink, addLinkInFlight] =
    useMutation<MovieEditPageAddLinkMutation>(graphql`
      mutation MovieEditPageAddLinkMutation(
        $input: AddExternalLinkInput!
        $connections: [ID!]!
      ) {
        add_external_link(input: $input) {
          ... on AddExternalLinkPayload {
            link
              @appendNode(
                connections: $connections
                edgeTypeName: "ExternalLinkEdge"
              ) {
              id
              name
              url
              favicon
            }
          }
          ... on Error {
            message
          }
        }
      }
    `);
  return (
    <div className="card overflow-hidden">
      <div className="card-title">相关链接</div>
      <div className="text-xs">
        {links.map(({ node: link }) => (
          <div
            key={link.id}
            className="flex items-center justify-between group border-b border-gray-200 p-4 last:border-0"
          >
            <div className="flex flex-col">
              <div className="flex items-center">
                <img
                  src={link.favicon}
                  alt={link.name}
                  className="w-4 h-4 mr-2 select-none"
                />
                <div className="font-bold text-gray-700">{link.name}</div>
              </div>
              <div className="ml-6 text-gray-500">{link.url}</div>
            </div>
            <FontAwesomeIcon
              icon={faSoildCircleXmark}
              className="text-gray-500 opacity-0 group-hover:opacity-100 cursor-pointer transition-opacity"
              onClickCapture={() => {
                deleteLink({
                  variables: {
                    id: link.id,
                    connections: [linksConnection],
                  },
                  onCompleted: (result) => {
                    if (result.delete_external_link?.message != null) {
                      enqueueSnackbar(
                        `删除链接失败：${result.delete_external_link.message}`,
                        {
                          variant: "error",
                        }
                      );
                    } else {
                      enqueueSnackbar("链接已删除", { variant: "success" });
                    }
                  },
                  onError: (error) => {
                    enqueueSnackbar(`删除链接失败：${error.message}`, {
                      variant: "error",
                    });
                  },
                });
              }}
            />
          </div>
        ))}
        <form
          className="p-2 bg-gray-50 flex items-center"
          onSubmitCapture={(e) => {
            e.preventDefault();
            addLink({
              variables: {
                input: {
                  url: newLink,
                  entity_id: entityId,
                },
                connections: [linksConnection],
              },
              onCompleted: (result) => {
                setNewLink("");
                if (result.add_external_link?.message != null) {
                  enqueueSnackbar(
                    `添加链接失败：${result.add_external_link.message}`,
                    { variant: "error" }
                  );
                } else {
                  enqueueSnackbar("链接已添加", { variant: "success" });
                }
              },
              onError: (error) => {
                enqueueSnackbar(`添加链接失败：${error.message}`, {
                  variant: "error",
                });
              },
            });
          }}
        >
          <input
            type="url"
            placeholder="添加相关链接"
            className="p-2 bg-gray-50 outline-none flex-1"
            value={newLink}
            onChange={(e) => {
              setNewLink(e.target.value);
            }}
            onFocus={() => {
              setActive(true);
            }}
            onBlur={() => {
              setActive(false);
            }}
          />
          <button type="submit" className="mr-2" disabled={addLinkInFlight}>
            <FontAwesomeIcon
              icon={faCirclePlus}
              className={`text-sm text-orange-600 opacity-0 transition-opacity ${showSubmitButtonStyle}`}
            />
          </button>
        </form>
      </div>
    </div>
  );
}

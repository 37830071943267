import React from "react";
import { useLazyLoadQuery, useMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import {
  isRouteErrorResponse,
  useNavigate,
  useRouteError,
} from "react-router-dom";
import Header from "@components/Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowLeft } from "@fortawesome/free-solid-svg-icons";
import NotFoundOasis from "./assets/not-found-oasis.png";

interface Props {}

function NotFoundError(_: Props): JSX.Element {
  const navigate = useNavigate();

  return (
    <div className="flex items-center justify-center mt-20">
      <img src={NotFoundOasis} alt="not found" className="w-72 select-none" />
      <div className="ml-4 mr-24">
        <h2 className="text-2xl text-orange-600 font-bold">
          哎呀！你似乎来到了无人的荒漠
        </h2>
        <button
          className="mt-4 text-gray-500 select-none hover:text-gray-600"
          type="button"
          onClick={() => {
            navigate(-1);
          }}
        >
          <FontAwesomeIcon icon={faCircleArrowLeft} className="mr-2" />
          返回上一页
        </button>
      </div>
    </div>
  );
}

export default function ErrorPage(_: Props): JSX.Element {
  const error = useRouteError();
  console.error(error);

  let notfound = false;
  let errorMessage = "Unknown error";
  if (error instanceof Error) {
    errorMessage = error.message;
  } else if (isRouteErrorResponse(error)) {
    errorMessage = error.statusText;
    if (error.status === 404) {
      notfound = true;
    }
  }

  return (
    <div className="w-[1040px] mx-auto">
      <Header />
      <div>
        {notfound ? (
          <NotFoundError />
        ) : (
          <div>
            <h2 className="text-xl text-orange-600 font-bold">出错啦！</h2>
            <p>对不起，</p>
            <p>
              <i>{errorMessage}</i>
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

import {
  useState,
  useEffect,
  type Dispatch,
  type SetStateAction,
  type MutableRefObject,
} from "react";

export type UseLoadMore = [boolean, Dispatch<SetStateAction<boolean>>];

export function useLoadMore(
  callback: () => void,
  componentRef: MutableRefObject<HTMLElement | null>
): UseLoadMore {
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const setIsLoadingMoreOnScroll =
    (ref: MutableRefObject<HTMLElement | null>) => () => {
      const { current } = ref;
      const { documentElement, body } = document;
      const windowOffset = window.innerHeight + documentElement.scrollTop;
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      const tableOffset = current ? current.scrollHeight : body.offsetHeight;
      if (windowOffset < tableOffset || isLoadingMore) {
        return;
      }
      setIsLoadingMore(true);
    };

  useEffect((): (() => void) => {
    const handleScroll = setIsLoadingMoreOnScroll(componentRef);
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (!isLoadingMore) {
      return;
    }
    callback();
  }, [isLoadingMore]);

  return [isLoadingMore, setIsLoadingMore];
}

export default { useLoadMore };

import React, { useEffect, useMemo, useState } from "react";
import Moment from "react-moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeart,
  faReply,
  faRetweet,
  faLock,
  faEnvelope,
  faMask,
  faAlignLeft,
  faBookmark,
} from "@fortawesome/free-solid-svg-icons";
import { Gallery, Item } from "react-photoswipe-gallery";
import { Link } from "react-router-dom";
import { type IconProp } from "@fortawesome/fontawesome-svg-core";
import { useRecoilValue } from "recoil";
import { type MastodonStatus } from "../__types__/MastodonStatus";
import "./status.css";
import {
  inlineEmoji,
  useMastodonApi,
  type Visibility,
} from "../utilities/Mastodon";
import { type MastodonAccount } from "../__types__/MastodonAccount";
import { CurrentDomain } from "../State";

interface Props {
  status: MastodonStatus;
  updateStatus: (id: string, field: Partial<MastodonStatus>) => void;
  secondary?: boolean;
  className?: string;
}

function getUserDisplayName(account: MastodonAccount): string {
  if (account.display_name.length === 0) {
    return account.username;
  }
  return account.display_name;
}

function StatusVisibility({
  visibility,
}: {
  visibility: MastodonStatus["visibility"];
}): JSX.Element {
  if (visibility === "public") {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }
  if (visibility === "unlisted") {
    return <FontAwesomeIcon icon={faMask} className="mr-2" />;
  }
  if (visibility === "private") {
    return <FontAwesomeIcon icon={faLock} className="mr-2" />;
  }
  if (visibility === "direct") {
    return <FontAwesomeIcon icon={faEnvelope} className="mr-2" />;
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
}

function MediaAttachments({
  id,
  attachments,
}: {
  id: string;
  attachments: MastodonStatus["media_attachments"];
}): JSX.Element {
  if (attachments.length === 0) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }
  return (
    <Gallery id={id}>
      <div
        className="grid gap-3 mt-2"
        style={{ gridTemplateColumns: "repeat(3, 1fr)" }}
      >
        {attachments.map((attach) => (
          <Item
            cropped
            original={attach.url}
            thumbnail={attach.preview_url}
            alt={attach.description}
            key={attach.id}
            width={attach.meta?.original?.width ?? 0}
            height={attach.meta?.original?.height ?? 0}
            content={
              attach.type !== "image" ? (
                // eslint-disable-next-line jsx-a11y/media-has-caption
                <video controls autoPlay loop aria-label={attach.description}>
                  <source src={attach.url} />
                </video>
              ) : undefined
            }
          >
            {({ ref, open }) => (
              /* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
              <img
                className="width-full max-h-full object-cover cursor-pointer select-none"
                src={attach.preview_url}
                ref={ref as React.MutableRefObject<HTMLImageElement>}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  open(e);
                }}
                alt={attach.description}
              />
            )}
          </Item>
        ))}
      </div>
    </Gallery>
  );
}

function ReblogLine({ status }: { status: MastodonStatus }): JSX.Element {
  return (
    <div className="reblog-line flex items-center px-5 py-2 bg-stone-100 text-gray-600 border-b border-stone-200 select-none dark:bg-gray-800 dark:border-gray-900">
      <FontAwesomeIcon icon={faRetweet} className="mr-2" />
      <Avatar account={status.account} className="w-6 h-6" />
      <span>
        <Username
          account={status.account}
          className="text-orange-700 font-medium"
        />{" "}
        转帖了
      </span>
    </div>
  );
}

function ReplyLine({ status }: { status: MastodonStatus }): JSX.Element {
  const isThread = status.in_reply_to_account_id === status.account.id;
  const target = status.mentions.filter(
    (m) => m.id === status.in_reply_to_account_id
  )[0];

  return (
    <Link to={`/@${status.account.acct}/${status.id}`} preventScrollReset>
      <div className="reblog-line flex items-center px-5 py-2 bg-stone-100 text-gray-600 border-b border-stone-200 select-none dark:bg-gray-800 dark:border-gray-900">
        <FontAwesomeIcon
          icon={isThread ? faAlignLeft : faReply}
          className="mr-2"
        />
        <span>{isThread ? "消息串" : "回复"}</span>
        {!isThread && target !== undefined && (
          <span className="text-orange-700 font-medium ml-1">
            {target.username}
          </span>
        )}
      </div>
    </Link>
  );
}

function StatusContent({ status }: { status: MastodonStatus }): JSX.Element {
  const content = useMemo((): string => {
    const { content: raw, emojis } = status;
    return inlineEmoji(raw, emojis);
  }, [status.content, status.emojis]);

  const spoiler = useMemo((): string => {
    const { spoiler_text: raw, emojis } = status;
    if (raw.length === 0) {
      return "";
    }
    return inlineEmoji(raw, emojis);
  }, [status.spoiler_text, status.emojis]);

  const [visible, setVisible] = useState(false);
  const [hidden, setHidden] = useState(false);

  useEffect(() => {
    if (visible) {
      const cancel = setTimeout(() => {
        setHidden(true);
      }, 500);

      return () => {
        clearTimeout(cancel);
      };
    }
    return () => {};
  }, [visible]);

  return (
    <div className="mastodon-html text-normal mt-1 select-text relative">
      {/* eslint-disable-next-line react/no-danger */}
      <div dangerouslySetInnerHTML={{ __html: content }} />
      {spoiler !== "" && (
        <>
          <div
            className={`absolute -left-1 -right-1 -bottom-1 -top-1 backdrop-blur-lg transition duration-300 cursor-pointer rounded-lg ${
              visible ? "backdrop-blur-0" : ""
            } ${hidden ? "hidden" : ""}`}
          />
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
          <div
            className={`absolute left-0 right-0 bottom-0 top-0 flex justify-center items-center font-bold text-lg transition-opacity duration-300 cursor-pointer opacity-100 ${
              visible ? "opacity-0" : ""
            } ${hidden ? "hidden" : ""}`}
            dangerouslySetInnerHTML={{ __html: spoiler }}
            onClick={() => {
              setVisible(true);
            }}
          />
        </>
      )}
    </div>
  );
}

function Username({
  account,
  className = "",
}: {
  account: MastodonAccount;
  className?: string;
}): JSX.Element {
  const displayName = getUserDisplayName(account);
  const content = useMemo((): string => {
    const { emojis } = account;
    return inlineEmoji(displayName, emojis);
  }, [account.emojis, account.username]);
  const currentDomain = useRecoilValue(CurrentDomain);
  let { acct } = account;
  if (currentDomain.length > 0 && !acct.includes("@")) {
    acct += `@${currentDomain}`;
  }

  return (
    <Link to={`/@${acct}`} className={`hover:underline ${className}`}>
      <span
        className="username select-text"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </Link>
  );
}

function Avatar({
  account,
  className = "",
}: {
  account: MastodonAccount;
  className?: string;
}): JSX.Element {
  return (
    <aside
      className={`grow-0 shrink-0 mr-3 rounded overflow-hidden ${className}`}
    >
      <Link to={`/@${account.acct}`}>
        <img src={account.avatar_static} alt={getUserDisplayName(account)} />
      </Link>
    </aside>
  );
}

type ActionType =
  | "reply"
  | "favourite"
  | "reblog"
  | "bookmark"
  | "unfavourite"
  | "unreblog"
  | "unbookmark";

function ActionTypeToIcon(act: ActionType): IconProp {
  switch (act) {
    case "reply":
      return faReply;
    case "favourite":
    case "unfavourite":
      return faHeart;
    case "reblog":
    case "unreblog":
      return faRetweet;
    case "bookmark":
    case "unbookmark":
      return faBookmark;
    default:
      return faHeart;
  }
}

function ActionButton({
  type,
  active,
  action,
  count,
  disabled = false,
}: {
  type: ActionType;
  active: boolean;
  action: (done: () => void) => void;
  count: number;
  disabled?: boolean;
}): JSX.Element {
  const [inProgress, setInProgress] = useState(false);
  const activeStyle =
    active || inProgress ? "text-orange-600" : "text-gray-400";
  const icon = ActionTypeToIcon(type);
  return (
    <button
      type="button"
      className={`mr-4 text-gray-400 disabled:text-gray-300 select-none py-2 px-4 hover:bg-gray-100 hover:disabled:bg-transparent dark:hover:bg-gray-700 dark:disabled:text-gray-500 disabled:cursor-not-allowed hover:text-orange-600 transition rounded-md cursor-pointer ${activeStyle}`}
      disabled={disabled}
      onClick={(e) => {
        e.stopPropagation();
        if (inProgress) return;
        setInProgress(true);
        action(() => {
          setInProgress(false);
        });
      }}
    >
      <FontAwesomeIcon icon={icon} />
      <span className="ml-3">{count}</span>
    </button>
  );
}

function ReplyForm({
  id,
  visibility,
  setFinished,
}: {
  id: string;
  visibility: Visibility;
  setFinished: () => void;
}): JSX.Element {
  const [reply, setReply] = useState("");
  const [replying, setReplying] = useState(false);
  const mastodonApi = useMastodonApi();
  return (
    <form
      className="flex mt-2 relative"
      onSubmitCapture={(e) => {
        e.preventDefault();
        setReplying(true);
        mastodonApi
          .postStatus(reply, { in_reply_to_id: id, visibility })
          .then(() => {
            setReply("");
            setFinished();
          })
          .catch((err) => {
            console.error(err);
          })
          .finally(() => {
            setReplying(false);
          });
      }}
    >
      <textarea
        placeholder="回复"
        className="bg-gray-100 rounded-lg w-full px-3 py-2 resize-none h-10 dark:bg-gray-700"
        value={reply}
        onChange={(e) => {
          setReply(e.target.value);
        }}
      />
      <div className="absolute right-2 top-0 bottom-0 flex items-center">
        <button
          disabled={replying}
          type="submit"
          className="bg-orange-600 text-white align-middle rounded-full aspect-square w-6 h-6 text-xs"
        >
          <FontAwesomeIcon icon={faReply} />
        </button>
      </div>
    </form>
  );
}

export default function TimelineStatus({
  status: statusProp,
  updateStatus,
  secondary = false,
  className = "",
}: Props): JSX.Element {
  const verb = "说";
  const status = statusProp.reblog ?? statusProp;
  const mastodonApi = useMastodonApi();
  const [showReply, setShowReply] = useState(false);

  return (
    <article
      className={`status card flex flex-col rounded-lg shadow-card overflow-hidden ${
        className ?? ""
      }`}
      onContextMenu={() => {
        console.log(statusProp);
      }}
    >
      {!(secondary ?? false) && (
        <>
          {statusProp.reblog != null ? (
            <ReblogLine status={statusProp} />
          ) : null}
          {statusProp.in_reply_to_id != null ? (
            <ReplyLine status={statusProp} />
          ) : null}
        </>
      )}
      <div className="flex p-5 pb-2">
        <Avatar account={status.account} className="w-12 h-12" />
        <div className="flex flex-col flex-1">
          <div className="username flex justify-between">
            <span>
              <span className="text-orange-700 font-medium">
                <Username account={status.account} />
              </span>
              <span className="verb text-gray-500 select-none">
                &nbsp;{verb}：
              </span>
            </span>
            <span className="float-right text-xs text-gray-500 select-none hover:underline">
              <StatusVisibility visibility={status.visibility} />
              <Link
                to={`/@${status.account.acct}/${status.id}`}
                preventScrollReset
              >
                <Moment fromNow locale="zh-cn">
                  {status.created_at}
                </Moment>
              </Link>
            </span>
          </div>
          <StatusContent status={status} />
          <MediaAttachments
            attachments={status.media_attachments}
            id={status.id}
          />
          <div aria-label="action" className="flex mt-2">
            <ActionButton
              type="reply"
              active={false}
              action={(done) => {
                setShowReply((prev) => {
                  done();
                  return !prev;
                });
              }}
              count={status.replies_count}
            />
            <ActionButton
              type={status.reblogged ? "unreblog" : "reblog"}
              active={status.reblogged}
              action={(done) => {
                let promise;
                if (status.reblogged) {
                  promise = mastodonApi.unreblog(status.id);
                } else {
                  promise = mastodonApi.reblog(status.id);
                }

                promise
                  .then(async (result) => {
                    const newStatus = (await result.json()) as MastodonStatus;
                    updateStatus(
                      status.id,
                      newStatus.reblog ?? {
                        reblogged: !status.reblogged,
                        reblogs_count:
                          status.reblogs_count + (status.reblogged ? -1 : 1),
                      }
                    );
                  })
                  .catch((err) => {
                    console.log(err);
                  })
                  .finally(() => {
                    done();
                  });
              }}
              count={status.reblogs_count}
              disabled={
                status.visibility === "direct" ||
                status.visibility === "private"
              }
            />
            <ActionButton
              type={status.favourited ? "unfavourite" : "favourite"}
              active={status.favourited}
              action={(done) => {
                let promise;
                if (status.favourited) {
                  promise = mastodonApi.unfavourite(status.id);
                } else {
                  promise = mastodonApi.favourite(status.id);
                }

                promise
                  .then(async (result) => {
                    const newStatus = (await result.json()) as MastodonStatus;
                    updateStatus(status.id, newStatus);
                  })
                  .catch((err) => {
                    console.log(err);
                  })
                  .finally(() => {
                    done();
                  });
              }}
              count={status.favourites_count}
            />
          </div>
          {showReply && (
            <ReplyForm
              id={status.id}
              visibility={status.visibility}
              setFinished={() => {
                setShowReply(false);
              }}
            />
          )}
        </div>
      </div>
    </article>
  );
}

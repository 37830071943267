/**
 * @generated SignedSource<<a0892612b2b48bb8fed3cd1924a46103>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type State_MessageQuery$variables = {};
export type State_MessageQuery$data = {
  readonly messages: ReadonlyArray<{
    readonly category: string;
    readonly message: string;
  }> | null;
};
export type State_MessageQuery = {
  response: State_MessageQuery$data;
  variables: State_MessageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Message",
    "kind": "LinkedField",
    "name": "messages",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "category",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "State_MessageQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "State_MessageQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "ee78778262f1580a7cbc00a6066323df",
    "id": null,
    "metadata": {},
    "name": "State_MessageQuery",
    "operationKind": "query",
    "text": "query State_MessageQuery {\n  messages {\n    category\n    message\n  }\n}\n"
  }
};
})();

(node as any).hash = "ec488386637eb9326e240685befb2197";

export default node;

import { RelayEnvironmentProvider } from "react-relay";
import React from "react";
import ReactDOM from "react-dom/client";
import { RecoilRoot } from "recoil";
import { RecoilRelayEnvironmentProvider } from "recoil-relay";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import Snackbar from "@components/Snackbar";
import { RelayEnvironment, RelayEnvironmentKey } from "./RelayEnvironment";
import "./index.css";
import "photoswipe/dist/photoswipe.css";
import reportWebVitals from "./reportWebVitals";
import "moment/locale/zh-cn";
import HomePage from "./home/HomePage";
import ErrorPage from "./ErrorPage";
import RootLayout from "./RootLayout";
import ErrorBoundary from "./components/ErrorBoundary";
import StatusPage from "./status/StatusPage";
import MoviePage from "./entity/MoviePage/MoviePage";
import SearchPage from "./search/SearchPage";
import MovieEditPage from "./entity/MovieEditPage/MovieEditPage";
import CreateEntityPage from "./entity/CreateEntityPage/CreateEntityPage";
import UserPage from "./user/UserPage";
import LandingPage from "./landing/LandingPage";
import MovieLandingPage from "./entity/MovieLandingPage/MovieLandingPage";

const router = createBrowserRouter([
  {
    path: "/login",
    element: <LandingPage />,
    errorElement: <ErrorPage />,
  },
  {
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        index: true,
        element: <HomePage />,
      },
      {
        path: "/search/:query",
        element: <SearchPage />,
      },
      {
        path: "/movie",
        element: <MovieLandingPage />,
      },
      {
        path: "/movie/:id",
        element: <MoviePage />,
      },
      {
        path: "/movie/create",
        element: <CreateEntityPage kind="movie" />,
      },
      {
        path: "/movie/:id/edit",
        element: <MovieEditPage />,
      },
      {
        path: "/music/create",
        element: <CreateEntityPage kind="music" />,
      },
      {
        path: "/book/create",
        element: <CreateEntityPage kind="book" />,
      },
      {
        path: "/game/create",
        element: <CreateEntityPage kind="game" />,
      },
      // NOTE: this must be at the end
      {
        path: "/:acct",
        element: <UserPage />,
      },
      {
        path: "/:acct/:statusId",
        element: <StatusPage />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <RelayEnvironmentProvider environment={RelayEnvironment}>
    <RecoilRoot>
      <RecoilRelayEnvironmentProvider
        environment={RelayEnvironment}
        environmentKey={RelayEnvironmentKey}
      >
        <React.StrictMode>
          <SnackbarProvider
            Components={{
              default: Snackbar,
              success: Snackbar,
              error: Snackbar,
              warning: Snackbar,
              info: Snackbar,
            }}
          >
            <RouterProvider router={router} />
          </SnackbarProvider>
        </React.StrictMode>
      </RecoilRelayEnvironmentProvider>
    </RecoilRoot>
  </RelayEnvironmentProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import graphql from "babel-plugin-relay/macro";
import { graphQLSelector } from "recoil-relay";
import { atom, selector, type AtomEffect } from "recoil";
import { RelayEnvironmentKey } from "./RelayEnvironment";
import { type State_CurrentUserQuery } from "./__generated__/State_CurrentUserQuery.graphql";
import { type State_EnvironmentQuery } from "./__generated__/State_EnvironmentQuery.graphql";
import { type State_MessageQuery } from "./__generated__/State_MessageQuery.graphql";

function gqlHack<T>(value: T): T {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  return (value as { default: T }).default;
}

// eslint-disable-next-line import/prefer-default-export
export const CurrentUser = graphQLSelector<
  State_CurrentUserQuery["variables"],
  State_CurrentUserQuery["response"]["current_user"]
>({
  key: "CurrentUser",
  environment: RelayEnvironmentKey,
  query: gqlHack(graphql`
    query State_CurrentUserQuery {
      current_user {
        username
        display_name
        access_token
        api_url
        stream_url
        avatar
        cover_image
      }
    }
  `),
  variables: () => ({}),
  mapResponse: (data: State_CurrentUserQuery["response"]) => data.current_user,
});

export const CurrentDomain = selector({
  key: "CurrentDomain",
  get: ({ get }): string => {
    const user = get(CurrentUser);
    if (user == null) {
      return "";
    }
    return user.username.split("@")[1] ?? "";
  },
});

export const HasAccount = selector({
  key: "HasAccount",
  get: ({ get }): boolean => {
    const user = get(CurrentUser);
    return user != null;
  },
});

export const Environment = graphQLSelector({
  key: "Environment",
  environment: RelayEnvironmentKey,
  query: gqlHack(graphql`
    query State_EnvironmentQuery {
      environment {
        env
        commit
      }
    }
  `),
  variables: () => ({}),
  mapResponse: (data: State_EnvironmentQuery["response"]) => data.environment,
});

export const Messages = graphQLSelector({
  key: "Messages",
  environment: RelayEnvironmentKey,
  query: gqlHack(graphql`
    query State_MessageQuery {
      messages {
        category
        message
      }
    }
  `),
  variables: () => ({}),
  mapResponse: (data: State_MessageQuery["response"]) => data.messages,
});

const localStorageEffect =
  <T>(key: string): AtomEffect<T> =>
  ({ setSelf, onSet }) => {
    if (typeof window === "undefined") {
      return;
    }

    const savedValue = localStorage.getItem(key);

    if (savedValue != null) {
      setSelf(JSON.parse(savedValue) as T);
    }

    onSet((newValue, _, isReset) => {
      isReset
        ? localStorage.removeItem(key)
        : localStorage.setItem(key, JSON.stringify(newValue));
    });
  };

export const ShareToMastodon = atom({
  key: "ShareToMastodon",
  default: true,
  effects: [localStorageEffect("ShareToMastodon")],
});

/**
 * @generated SignedSource<<44ab4eb854a44df332acb62cab3c00bb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type State_CurrentUserQuery$variables = {};
export type State_CurrentUserQuery$data = {
  readonly current_user: {
    readonly access_token: string;
    readonly api_url: string;
    readonly avatar: string | null;
    readonly cover_image: string | null;
    readonly display_name: string;
    readonly stream_url: string;
    readonly username: string;
  } | null;
};
export type State_CurrentUserQuery = {
  response: State_CurrentUserQuery$data;
  variables: State_CurrentUserQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "current_user",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "username",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "display_name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "access_token",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "api_url",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "stream_url",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "avatar",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cover_image",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "State_CurrentUserQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "State_CurrentUserQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "b5de1aa7b61b0631a4402be4a0e629ed",
    "id": null,
    "metadata": {},
    "name": "State_CurrentUserQuery",
    "operationKind": "query",
    "text": "query State_CurrentUserQuery {\n  current_user {\n    username\n    display_name\n    access_token\n    api_url\n    stream_url\n    avatar\n    cover_image\n  }\n}\n"
  }
};
})();

(node as any).hash = "73a9e38a8c89c5e5ec4dd3fd2f5696e7";

export default node;

/**
 * @generated SignedSource<<c0f86f1b83e36493aa9c0d351fe83e6f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserPage_activities$data = {
  readonly finished: {
    readonly " $fragmentSpreads": FragmentRefs<"UserPage_activityLine">;
  };
  readonly in_progress: {
    readonly " $fragmentSpreads": FragmentRefs<"UserPage_activityLine">;
  };
  readonly wishlist: {
    readonly " $fragmentSpreads": FragmentRefs<"UserPage_activityLine">;
  };
  readonly " $fragmentType": "UserPage_activities";
};
export type UserPage_activities$key = {
  readonly " $data"?: UserPage_activities$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserPage_activities">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "UserPage_activityLine"
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserPage_activities",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ActivityConnection",
      "kind": "LinkedField",
      "name": "wishlist",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ActivityConnection",
      "kind": "LinkedField",
      "name": "in_progress",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ActivityConnection",
      "kind": "LinkedField",
      "name": "finished",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "UserActivities",
  "abstractKey": null
};
})();

(node as any).hash = "6b15ece3cf556fefc8f4c61283b31d2d";

export default node;

/**
 * @generated SignedSource<<f35d57f8f99b64fbaeb54ad479aa89cf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EntityKind = "book" | "game" | "movie" | "music" | "%future added value";
export type CreateEntityInput = {
  cover?: any | null;
  kind?: EntityKind | null;
  link?: string | null;
  movie?: CreateEntityMovieInput | null;
};
export type CreateEntityMovieInput = {
  actors: ReadonlyArray<string>;
  aliases: ReadonlyArray<string>;
  countries: ReadonlyArray<string>;
  description: string;
  directors: ReadonlyArray<string>;
  genres: ReadonlyArray<string>;
  languages: ReadonlyArray<string>;
  name: string;
  original_name: string;
  release_date: string;
};
export type CreateEntityPageCreateEntityMutation$variables = {
  input: CreateEntityInput;
};
export type CreateEntityPageCreateEntityMutation$data = {
  readonly create_entity: {
    readonly __typename: string;
    readonly message?: string;
    readonly movie?: {
      readonly id: string;
    } | null;
    readonly path?: string;
  };
};
export type CreateEntityPageCreateEntityMutation = {
  response: CreateEntityPageCreateEntityMutation$data;
  variables: CreateEntityPageCreateEntityMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "create_entity",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Movie",
            "kind": "LinkedField",
            "name": "movie",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "type": "CreateEntityPayload",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "path",
            "storageKey": null
          }
        ],
        "type": "AlreadyExists",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "type": "Error",
        "abstractKey": "__isError"
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateEntityPageCreateEntityMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateEntityPageCreateEntityMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "2414d139b161d4d39317699f80a268a6",
    "id": null,
    "metadata": {},
    "name": "CreateEntityPageCreateEntityMutation",
    "operationKind": "mutation",
    "text": "mutation CreateEntityPageCreateEntityMutation(\n  $input: CreateEntityInput!\n) {\n  create_entity(input: $input) {\n    __typename\n    ... on CreateEntityPayload {\n      movie {\n        id\n      }\n    }\n    ... on AlreadyExists {\n      path\n    }\n    ... on Error {\n      __isError: __typename\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "66a6b8c827d1230bd5ea3dbd7025e0be";

export default node;

import React from "react";
import { useLazyLoadQuery, useMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";

interface Props {
  children: JSX.Element;
}

interface State {
  hasError: boolean;
}

export default class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_error: Error): State {
    return { hasError: true };
  }

  componentDidCatch(_error: Error, _errorInfo: unknown): void {
    // You can also log the error to an error reporting service    logErrorToMyService(error, errorInfo);  }
  }

  render(): JSX.Element {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      // You can render any custom fallback UI      return <h1>Something went wrong.</h1>;    }
      return <h1>Something went wrong.</h1>;
    }
    return children;
  }
}

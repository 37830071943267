import React, { useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightToBracket, faSearch } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate, useParams, type To } from "react-router-dom";
import { useRecoilValue } from "recoil";
import logo from "../logo.svg";
import { HasAccount } from "../State";

interface Props {}

function NavigationButton({ name, to }: { name: string; to: To }): JSX.Element {
  return (
    <Link to={to}>
      <div className="px-4 py-2 mx-1 cursor-pointer text-gray-600 hover:bg-gray-100 hover:text-orange-600 font-medium rounded-md transition select-none dark:hover:bg-gray-800">
        {name}
      </div>
    </Link>
  );
}

export default function Header(_: Props): JSX.Element {
  const navigate = useNavigate();
  const params = useParams();
  const hasAccount = useRecoilValue(HasAccount);
  const [query, setQuery] = React.useState("");
  useMemo(() => {
    if (params.query != null) {
      setQuery(params.query);
      return;
    }
    setQuery("");
  }, [params]);

  return (
    <div className="pt-12 mb-4 md:mb-10 flex justify-between items-center flex-col md:flex-row">
      <div className="flex flex-col md:flex-row items-center select-none md:w-2/3 md:mr-2">
        <Link aria-label="logo" to="/" className="shrink-0">
          <img src={logo} alt="Beta Town logo" />
        </Link>
        <div className="flex mt-4 md:mt-0 md:ml-8">
          <NavigationButton name="首页" to="/" />
          <NavigationButton name="电影" to="/movie" />
          <NavigationButton name="书籍" to="/" />
          <NavigationButton name="音乐" to="/" />
          <NavigationButton name="游戏" to="/" />
        </div>
      </div>
      <div className="px-2 w-full md:w-1/3 mt-4 md:mt-0">
        {hasAccount ? (
          <form
            className="relative"
            onSubmitCapture={(e) => {
              e.preventDefault();
              navigate(`/search/${query}`);
            }}
          >
            <input
              type="text"
              className="w-full h-10 rounded-md shadow-card px-4 dark:bg-gray-800"
              placeholder="搜索..."
              onChange={(e) => {
                setQuery(e.target.value);
              }}
              value={query}
            />
            <button
              type="submit"
              className="absolute top-2 right-3 text-gray-400"
            >
              <FontAwesomeIcon icon={faSearch} />
            </button>
          </form>
        ) : (
          <button
            type="button"
            className="text-orange-600 font-bold hover:text-orange-500"
            onClickCapture={() => {
              window.location.href = "/login";
            }}
          >
            <FontAwesomeIcon icon={faRightToBracket} className="mr-2" />
            登录
          </button>
        )}
      </div>
    </div>
  );
}

import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import VisibilitySelect from "../components/VisibilitySelect";
import { useMastodonApi, type Visibility } from "../utilities/Mastodon";

interface Props {}

export default function StatusComposer(_: Props): JSX.Element {
  const [value, setValue] = React.useState("");
  const [focused, setFocused] = React.useState(false);
  const [fetching, setFetching] = React.useState(false);
  const expanded = value.length > 0;
  const expandedClass = expanded ? "h-36" : "h-24";
  const [visibility, setVisibility] = useState<Visibility>("public");
  const { enqueueSnackbar } = useSnackbar();

  const mastodonApi = useMastodonApi();

  const sendStatus = (): void => {
    mastodonApi
      .postStatus(value, {
        visibility,
      })
      .then((result) => {
        enqueueSnackbar("嘟嘟已发送", { variant: "success" });
        setValue("");
      })
      .catch((error) => {
        if (error instanceof Error) {
          enqueueSnackbar(`嘟嘟发送失败：${error.message}`, {
            variant: "error",
          });
        } else {
          // eslint-disable-next-line no-console
          console.error("嘟嘟发送失败", error);
          enqueueSnackbar(`嘟嘟发送失败`, {
            variant: "error",
          });
        }
      })
      .finally(() => {
        setFetching(false);
      });

    setFetching(true);
  };

  return (
    <form
      className="mb-6 relative"
      aria-label="composer"
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <textarea
        name="status"
        className={`w-full p-4 resize-none rounded-md shadow-card transition-all duration-300 ${expandedClass} dark:bg-gray-800`}
        placeholder="分享生活点滴..."
        onFocus={() => {
          setFocused(true);
        }}
        onBlur={() => {
          setFocused(false);
        }}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        value={value}
        disabled={fetching}
      />
      <div
        className="absolute right-3 bottom-3 transition opacity duration-300 flex items-center"
        style={{
          opacity: expanded ? 1 : 0,
        }}
      >
        <VisibilitySelect
          className="mr-2"
          value={visibility}
          onChange={setVisibility}
        />
        <button
          type="submit"
          className="bg-orange-600 rounded-lg text-white dark:bg-orange-800 px-6 py-2"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            sendStatus();
          }}
          disabled={fetching}
        >
          {fetching ? <FontAwesomeIcon icon={faSpinner} spin /> : "嘟嘟"}
        </button>
      </div>
    </form>
  );
}

import React, { Suspense } from "react";
import { useRecoilValue } from "recoil";
import { Environment } from "../State";

interface Props {}

function EnvironmentBannerInner(_: Props): JSX.Element {
  const environment = useRecoilValue(Environment);

  if (environment == null || environment.env === "production") {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }

  const color = environment.env === "staging" ? "bg-yellow-500" : "bg-red-400";

  return (
    <div className={`py-1 px-2 text-white text-center ${color}`}>
      {environment.env} build
    </div>
  );
}

export default function EnvironmentBanner(_: Props): JSX.Element {
  return (
    <Suspense>
      <EnvironmentBannerInner />
    </Suspense>
  );
}

import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import { faRotateRight } from "@fortawesome/free-solid-svg-icons";

export default function ChipsInput({
  id,
  label,
  value,
  onChange,
  twoline = false,
  width = "w-96",
  reset = false,
  onReset = null,
}: {
  id: string;
  label: string;
  value: string[];
  onChange: (n: string[]) => void;
  twoline?: boolean;
  width?: string;
  reset?: boolean;
  onReset?: (() => void) | null;
}): JSX.Element {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [active, setActive] = React.useState(false);
  const activeStyle = active ? "outline outline-blue-500 outline-2" : "";

  useEffect((): (() => void) => {
    const input = inputRef.current;
    if (input == null) {
      return () => {};
    }
    const onKeyDown = (e: KeyboardEvent): void => {
      if (e.key === "Enter") {
        e.preventDefault();
        if (input.value === "") {
          return;
        }
        onChange([...value, input.value.trim()]);
        input.value = "";
      } else if (
        e.key === "Backspace" &&
        input.value === "" &&
        value.length > 0
      ) {
        e.preventDefault();
        const last = value[value.length - 1];
        onChange(value.slice(0, -1));
        input.value = last;
      }
    };
    input.addEventListener("keydown", onKeyDown);
    return (): void => {
      input.removeEventListener("keydown", onKeyDown);
    };
  }, [value, onChange]);

  useEffect((): (() => void) => {
    const input = inputRef.current;
    if (input == null) {
      return () => {};
    }

    const onBlur = (): void => {
      if (input.value === "") {
        return;
      }
      onChange([...value, input.value.trim()]);
      input.value = "";
    };
    input.addEventListener("blur", onBlur);
    return (): void => {
      input.removeEventListener("blur", onBlur);
    };
  }, [value, onChange]);

  return (
    <div className={`flex items-start ${twoline ? "flex-col" : ""}`}>
      <label
        htmlFor={id}
        className={`text-sm font-medium text-gray-700 w-32 inline-block mr-4 flex-0 leading-8 ${
          twoline ? "text-left" : "text-right"
        }`}
      >
        {label}
        {onReset !== null && reset && (
          <FontAwesomeIcon
            icon={faRotateRight}
            className="ml-2 text-orange-400 cursor-pointer"
            onClickCapture={() => {
              onReset();
            }}
          />
        )}
      </label>
      <ul
        className={`border border-gray-200 px-2 pb-1 rounded inline-flex items-center flex-wrap ${activeStyle} ${width}`}
      >
        {value.map((v) => (
          <li
            className="rounded bg-orange-100 py-1 px-2 flex-0 mt-1 mr-1 inline text-orange-600 select-none text-xs hover:bg-orange-200"
            key={v}
          >
            {v}
            <FontAwesomeIcon
              icon={faCircleXmark}
              className="ml-1 text-orange-600 cursor-pointer"
              onClickCapture={() => {
                onChange(value.filter((x) => x !== v));
              }}
            />
          </li>
        ))}
        <li className="inline-block mt-1 flex-1">
          <input
            className="outline-none py-1 px-1 w-full min-w-[128px] text-xs "
            type="text"
            ref={inputRef}
            onFocus={() => {
              setActive(true);
            }}
            onBlur={() => {
              setActive(false);
            }}
          />
        </li>
      </ul>
    </div>
  );
}
